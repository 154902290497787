import React, {
  useState, useRef, useEffect 
} from "react";
import styled from "styled-components";
import { styles } from "src/lib/styles";

interface TimeRangeSliderProps {
  startTime: string;
  endTime: string;
  onChange: (startTime: string, endTime: string) => void;
}

const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 1.5rem 0;
  padding: 0 1rem;
  box-sizing: border-box;
  background: ${styles.primaryDarkColor};
  border-radius: 0.5rem;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  background: ${styles.primaryDarkColor};
  border-radius: 0.5rem;
`;

const SliderTrack = styled.div`
  position: absolute;
  height: 3rem;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  box-sizing: border-box;
`;

const SliderHandle = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  background: ${styles.primaryAccentColor};
  border-radius: 50%;
  transform: translate(-50%, -20px);
  cursor: pointer;  
  
  &:hover {
    background: ${styles.primaryAccentColor};
  }
  
  &:active {
    background: ${styles.primaryAccentColor};
  }
`;

const TimeLabel = styled.div`
  position: absolute;
  top: -25px;
  transform: translateX(-50%);
  font-size: 12px;
  font-family: "Roboto Mono";
  color: ${styles.secondaryTextColor};
`;

const TimeMarkers = styled.div`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;

`;

const TimeMarker = styled.div<{ isHourWithinStartAndEnd: boolean }>`
  width: 2px;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  height: ${props => props.isHourWithinStartAndEnd ? "1rem" : ".5rem"};
  background: ${props => props.isHourWithinStartAndEnd ? styles.primaryAccentColor : styles.secondaryTextColor};
`;

const TimeRangeSlider: React.FC<TimeRangeSliderProps> = ({
  startTime,
  endTime,
  onChange
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState<"start" | "end" | null>(null);
  const [startPos, setStartPos] = useState(0);
  const [endPos, setEndPos] = useState(100);
  const MIN_TIME = 0; // 00:00
  const MAX_TIME = 23.99; // 23:59
  const TOTAL_MINUTES = (MAX_TIME - MIN_TIME) * 60;
  const MINUTES_PER_STEP = 5; // Snap to 5-minute intervals

  const snapToGrid = (position: number): number => {
    // Convert position percentage to minutes
    const minutes = (position / 100) * TOTAL_MINUTES;
    // Round to nearest 5-minute interval
    const snappedMinutes = Math.round(minutes / MINUTES_PER_STEP) * MINUTES_PER_STEP;

    // Convert back to percentage
    return (snappedMinutes / TOTAL_MINUTES) * 100;
  };

  useEffect(() => {
    // Convert time strings to positions
    const startMinutes = timeToMinutes(startTime);
    const endMinutes = timeToMinutes(endTime);
    
    setStartPos(((startMinutes - MIN_TIME * 60) / TOTAL_MINUTES) * 100);
    setEndPos(((endMinutes - MIN_TIME * 60) / TOTAL_MINUTES) * 100);
  }, [
    startTime,
    endTime,
    TOTAL_MINUTES
  ]);

  const timeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);

    return hours * 60 + minutes;
  };

  const minutesToTime = (position: number) => {
    // Convert position percentage to total minutes and snap to 5-minute intervals
    const totalMinutes = Math.round(((position / 100) * TOTAL_MINUTES) / MINUTES_PER_STEP) * MINUTES_PER_STEP + MIN_TIME * 60;
    const hours = Math.floor(totalMinutes / 60);
    const mins = totalMinutes % 60;

    return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
  };

  const handleMouseDown = (handle: "start" | "end") => {
    setIsDragging(handle);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging || !containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    const pos = ((e.touches[0].clientX - rect.left) / rect.width) * 100;
    const boundedPos = Math.max(0, Math.min(100, pos));
    const snappedPos = snapToGrid(boundedPos);

    if (isDragging === "start") {
      if (snappedPos < endPos) {
        setStartPos(snappedPos);
        onChange(minutesToTime(snappedPos), minutesToTime(endPos));
      }
    } else {
      if (snappedPos > startPos) {
        setEndPos(snappedPos);
        onChange(minutesToTime(startPos), minutesToTime(snappedPos));
      }
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !containerRef.current) return;

    const rect = containerRef.current.getBoundingClientRect();
    const pos = ((e.clientX - rect.left) / rect.width) * 100;
    const boundedPos = Math.max(0, Math.min(100, pos));
    const snappedPos = snapToGrid(boundedPos);

    if (isDragging === "start") {
      if (snappedPos < endPos) {
        setStartPos(snappedPos);
        onChange(minutesToTime(snappedPos), minutesToTime(endPos));
      }
    } else {
      if (snappedPos > startPos) {
        setEndPos(snappedPos);
        onChange(minutesToTime(startPos), minutesToTime(snappedPos));
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(null);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mouseup", handleMouseUp);

      return () => document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => null;
  }, [isDragging]);

  const handleTrackClick = (e: React.MouseEvent) => {
    if (!containerRef.current) return;
    
    const rect = containerRef.current.getBoundingClientRect();
    const pos = ((e.clientX - rect.left) / rect.width) * 100;
    const boundedPos = Math.max(0, Math.min(100, pos));
    const snappedPos = snapToGrid(boundedPos);
    // Determine which handle is closer to the click position
    const distanceToStart = Math.abs(snappedPos - startPos);
    const distanceToEnd = Math.abs(snappedPos - endPos);
    const handle = distanceToStart < distanceToEnd ? "start" : "end";
    
    // Set the position and begin dragging
    if (handle === "start" && snappedPos < endPos) {
      setStartPos(snappedPos);
      onChange(minutesToTime(snappedPos), minutesToTime(endPos));
      setIsDragging("start");
    } else if (handle === "end" && snappedPos > startPos) {
      setEndPos(snappedPos);
      onChange(minutesToTime(startPos), minutesToTime(snappedPos));
      setIsDragging("end");
    }
  };

  return (
    <BackgroundContainer>
      <SliderContainer
        ref={containerRef}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchMove={handleTouchMove}
        onTouchCancel={handleMouseUp}
      >
        <SliderTrack onClick={handleTrackClick} />
        <SliderHandle
          style={{
            left: `${startPos}%`,
            top: "50%" 
          }}
          onMouseDown={() => handleMouseDown("start")}
          onTouchStart={() => handleMouseDown("start")}
        >
          <TimeLabel>{minutesToTime(startPos)}</TimeLabel>
        </SliderHandle>
        <SliderHandle
          style={{
            left: `${endPos}%`,
            top: "50%" 
          }}
          onMouseDown={() => handleMouseDown("end")}
          onTouchStart={() => handleMouseDown("end")}
        >
          <TimeLabel>{minutesToTime(endPos)}</TimeLabel>
        </SliderHandle>
        <TimeMarkers onMouseDown={handleTrackClick}>
          {Array.from({ length: 48 }, (_, i) => (
            <TimeMarker 
              key={i} 
              isHourWithinStartAndEnd={i * 30 >= timeToMinutes(startTime) && i * 30 <= timeToMinutes(endTime)} 
            />
          ))}
        </TimeMarkers>
      </SliderContainer>
    </BackgroundContainer>
  );
};

export default TimeRangeSlider; 
