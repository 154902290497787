import * as AT from "./camera-locations.constants";
import { IStore } from "../../types/store/store";
import * as Redux from "redux";
import { ThunkAction } from "redux-thunk";
import {
  goBack, RouterAction, push, replace
} from "react-router-redux";
import { toast } from "react-toastify";
import apiError from "src/lib/apiError";
import {
  AdjustableSchedule, AdjustableSetting, ICameraLocation 
} from "src/store/camera-locations/camera-locations.api";
import * as cameraLocationsApi from "./camera-locations.api";
import { PaginatedParams } from "../../types/pagination/index";

export type CameraLocationActions =
  | ISetCameraLocation
  | IPurgeCameraLocations
  | IGetCameraLocation
  | IGetCameraLocationSuccess
  | IGetCameraLocationFailure
  | IGetCameraLocationCounts
  | IGetCameraLocationCountsSuccess
  | IGetCameraLocationCountsFailure
  | IGetCameraLocations
  | IGetCameraLocationsSuccess
  | IGetCameraLocationsFailure
  | IRefreshLatestImage
  | IRefreshLatestImageSuccess
  | IRefreshLatestImageFailure
  | ICreateCameraLocation
  | ICreateCameraLocationSuccess
  | ICreateCameraLocationFailure
  | IUpdateCameraLocation
  | IUpdateCameraLocationSuccess
  | IUpdateCameraLocationFailure
  | ISuspendCameraLocation
  | ISuspendCameraLocationSuccess
  | ISuspendCameraLocationFailure
  | IArchiveCameraLocation
  | IArchiveCameraLocationSuccess
  | IArchiveCameraLocationFailure
  | IDeleteCameraLocation
  | IDeleteCameraLocationSuccess
  | IDeleteCameraLocationFailure
  | IUpdateCameraConfig
  | IUpdateCameraConfigSuccess
  | IUpdateCameraConfigFailure
  | IUpdateCameraSchedule
  | IUpdateCameraScheduleSuccess
  | IUpdateCameraScheduleFailure;

type ThunkResult<R> = ThunkAction<
  R,
  IStore,
  undefined,
  CameraLocationActions | RouterAction
>;

export interface IPurgeCameraLocations {
  type: AT.PURGE_CAMERA_LOCATIONS;
}

export const purgeCameraLocations: Redux.ActionCreator<IPurgeCameraLocations> = () => {
  return { type: AT.PURGE_CAMERA_LOCATIONS };
};

export interface ISetCameraLocation {
  type: AT.SET_CAMERA_LOCATION;
}

export const setCameraLocation: Redux.ActionCreator<ISetCameraLocation> = () => {
  return { type: AT.SET_CAMERA_LOCATION };
};

// ----------- GET CAMERA_LOCATION ACTION -------- //
export interface IGetCameraLocation {
  type: AT.GET_CAMERA_LOCATION;
}

export interface IGetCameraLocationSuccess {
  cameraLocation: ICameraLocation;
  type: AT.GET_CAMERA_LOCATION_SUCCESS;
}

export interface IGetCameraLocationFailure {
  type: AT.GET_CAMERA_LOCATION_FAILURE;
}

export const getCameraLocation: Redux.ActionCreator<IGetCameraLocation> = () => {
  return { type: AT.GET_CAMERA_LOCATION };
};

export const getCameraLocationSuccess: Redux.ActionCreator<IGetCameraLocationSuccess> = (cameraLocation: ICameraLocation, totals) => {
  return {
    cameraLocation,
    type: AT.GET_CAMERA_LOCATION_SUCCESS
  };
};

export const getCameraLocationFailure: Redux.ActionCreator<IGetCameraLocationFailure> = () => {
  return { type: AT.GET_CAMERA_LOCATION_FAILURE };
};

export const getCameraLocationRequest = (cameraLocationId: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getCameraLocation());

    try {
      const cameraLocation = await cameraLocationsApi.getCameraLocation(cameraLocationId);

      dispatch(getCameraLocationSuccess(cameraLocation));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getCameraLocationsFailure());
      toast.error(apiError(error));
    }
  };
};
// END CAMERA LOCATION ACTION

// ----------- GET CAMERA_LOCATIONS ACTIONS -------- //
export interface IGetCameraLocationCounts {
  type: AT.GET_CAMERA_LOCATION_COUNTS;
}

export interface IGetCameraLocationCountsSuccess {
  totals: CameraTotals;
  type: AT.GET_CAMERA_LOCATION_COUNTS_SUCCESS;
}

export interface IGetCameraLocationCountsFailure {
  type: AT.GET_CAMERA_LOCATION_COUNTS_FAILURE;
}

export interface IGetCameraLocations {
  type: AT.GET_CAMERA_LOCATIONS;
}

export interface IGetCameraLocationsSuccess {
  cameraLocations: ICameraLocation[];
  totals: CameraTotals;
  count: number;
  type: AT.GET_CAMERA_LOCATIONS_SUCCESS;
}

export interface IGetCameraLocationsFailure {
  type: AT.GET_CAMERA_LOCATIONS_FAILURE;
}

export interface CameraTotals {
  allTotal: number;
  liveTotal: number;
  suspendedTotal: number;
  finishedTotal: number;
  archivedTotal: number;
}

export const getCameraLocationCounts: Redux.ActionCreator<IGetCameraLocationCounts> = () => {
  return { type: AT.GET_CAMERA_LOCATION_COUNTS };
};

export const getCameraLocationCountsSuccess: Redux.ActionCreator<IGetCameraLocationCountsSuccess> = totals => {
  return {
    totals,
    type: AT.GET_CAMERA_LOCATION_COUNTS_SUCCESS
  };
};

export const getCameraLocationCountsFailure: Redux.ActionCreator<IGetCameraLocationCountsFailure> = () => {
  return { type: AT.GET_CAMERA_LOCATION_COUNTS_FAILURE };
};

export interface IRefreshLatestImage {
  type: AT.REFRESH_LATEST_IMAGE;
}

export interface IRefreshLatestImageSuccess {
  type: AT.REFRESH_LATEST_IMAGE_SUCCESS;
}

export interface IRefreshLatestImageFailure {
  type: AT.REFRESH_LATEST_IMAGE_FAILURE;
}

export const refreshLatestImage: Redux.ActionCreator<IRefreshLatestImage> = () => {
  return { type: AT.REFRESH_LATEST_IMAGE };
};

export const refreshLatestImageSuccess: Redux.ActionCreator<IRefreshLatestImageSuccess> = () => {
  return { type: AT.REFRESH_LATEST_IMAGE_SUCCESS };
};

export const refreshLatestImageFailure: Redux.ActionCreator<IRefreshLatestImageFailure> = () => {
  return { type: AT.REFRESH_LATEST_IMAGE_FAILURE };
};

export const refreshLatestImageRequest = (cameraLocationId: number, callBack: () => void): ThunkResult<void> => {
  return async dispatch => {
    dispatch(refreshLatestImage());

    try {
      await cameraLocationsApi.refreshLatestImage(cameraLocationId);
      dispatch(refreshLatestImageSuccess());
      callBack();
    } catch (error) {
      dispatch(refreshLatestImageFailure());
      toast.error(apiError(error));
    }
  };
};

export const getCameraLocations: Redux.ActionCreator<IGetCameraLocations> = () => {
  return { type: AT.GET_CAMERA_LOCATIONS };
};

export const getCameraLocationsSuccess: Redux.ActionCreator<IGetCameraLocationsSuccess> = (
  cameraLocations: ICameraLocation[],
  count: number, totals: CameraTotals
) => {
  return {
    cameraLocations,
    count,
    totals,
    type: AT.GET_CAMERA_LOCATIONS_SUCCESS
  };
};

export const getCameraLocationsFailure: Redux.ActionCreator<IGetCameraLocationsFailure> = () => {
  return { type: AT.GET_CAMERA_LOCATIONS_FAILURE };
};

export const getCameraLocationsRequest = (
  siteId: string,
  role = "client",
  status?: string,
  is_finished = 0,
  redirect = false,
  pagination?: PaginatedParams<ICameraLocation>
): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getCameraLocations());

    try {
      const cameraLocations = await cameraLocationsApi.getCameraLocations(
        siteId,
        role,
        pagination,
        status,
        is_finished
      );

      const result = cameraLocations.result || cameraLocations;

      dispatch(getCameraLocationsSuccess(
        result,
        cameraLocations.count || 0,
        cameraLocations.totals
      ));

      if (redirect) {
        if (result) {
          dispatch(replace(window.location.pathname.replace("find", result[0].id.toString())));
        } else {
          dispatch(push("/error", {
            code: "404",
            message: "This site currently has no cameras!"
          }));
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getCameraLocationsFailure());
      toast.error(apiError(error));
    }
  };
};

export const getCameraLocationCountsRequest = (siteId?: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getCameraLocationCounts());

    try {
      const cameraLocationCounts = await cameraLocationsApi.getCameraLocationCounts(siteId);

      dispatch(getCameraLocationCountsSuccess(cameraLocationCounts));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getCameraLocationCountsFailure());
      toast.error(apiError(error));
    }
  };
};

export const getAllCameraLocationsRequest = (
  pagination: PaginatedParams<ICameraLocation>,
  filters: Partial<ICameraLocation>,
  _redirect = false
): ThunkResult<void> => {
  return async dispatch => {
    dispatch(getCameraLocations());

    try {
      const cameraLocations = await cameraLocationsApi.getAllCameraLocations(pagination, filters);

      dispatch(getCameraLocationsSuccess(
        cameraLocations.result || cameraLocations, // fallback for seemless deploy (TODO remove || cameraLocations),
        cameraLocations.count,
        cameraLocations.totals
      ));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(getCameraLocationsFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END GET CAMERA_LOCATIONS ACTIONS -------- //

// ----------- CREATE CAMERA_LOCATIONS ACTIONS -------- //
export interface ICreateCameraLocation {
  type: AT.CREATE_CAMERA_LOCATION;
}

export interface ICreateCameraLocationSuccess {
  type: AT.CREATE_CAMERA_LOCATION_SUCCESS;
}

export interface ICreateCameraLocationFailure {
  type: AT.CREATE_CAMERA_LOCATION_FAILURE;
}

export const createCameraLocation: Redux.ActionCreator<ICreateCameraLocation> = () => {
  return { type: AT.CREATE_CAMERA_LOCATION };
};

export const createCameraLocationSuccess: Redux.ActionCreator<ICreateCameraLocationSuccess> = () => {
  return { type: AT.CREATE_CAMERA_LOCATION_SUCCESS };
};

export const createCameraLocationFailure: Redux.ActionCreator<ICreateCameraLocationFailure> = () => {
  return { type: AT.CREATE_CAMERA_LOCATION_FAILURE };
};

export const createCameraLocationRequest = (cameraLocation: ICameraLocation): ThunkResult<void> => {
  return async dispatch => {
    dispatch(createCameraLocation());

    try {
      const newCameraLocation = await cameraLocationsApi.createCameraLocation(cameraLocation);

      dispatch(createCameraLocationSuccess());
      dispatch(getCameraLocationsRequest(newCameraLocation.site_id));
      dispatch(goBack());
      toast.success("Successfully created camera-location!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(createCameraLocationFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END CREATE CAMERA_LOCATIONS ACTIONS -------- //

// ----------- UPDATE CAMERA_LOCATIONS ACTIONS -------- //
export interface IUpdateCameraLocation {
  type: AT.UPDATE_CAMERA_LOCATION;
}

export interface IUpdateCameraLocationSuccess {
  type: AT.UPDATE_CAMERA_LOCATION_SUCCESS;
}

export interface IUpdateCameraLocationFailure {
  type: AT.UPDATE_CAMERA_LOCATION_FAILURE;
}

export const updateCameraLocation: Redux.ActionCreator<IUpdateCameraLocation> = () => {
  return { type: AT.UPDATE_CAMERA_LOCATION };
};

export const updateCameraLocationSuccess: Redux.ActionCreator<IUpdateCameraLocationSuccess> = () => {
  return { type: AT.UPDATE_CAMERA_LOCATION_SUCCESS };
};

export const updateCameraLocationFailure: Redux.ActionCreator<IUpdateCameraLocationFailure> = () => {
  return { type: AT.UPDATE_CAMERA_LOCATION_FAILURE };
};

export const updateCameraLocationRequest = (cameraLocationId: string,
  cameraLocation: ICameraLocation): ThunkResult<void> => {
  return async dispatch => {
    dispatch(updateCameraLocation());

    try {
      await cameraLocationsApi.updateCameraLocation(cameraLocationId,
        cameraLocation);
      dispatch(updateCameraLocationSuccess());
      toast.success("Successfully updated camera-location details!");
      dispatch(getCameraLocationRequest(cameraLocation.id.toString()));
      dispatch(goBack());
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(updateCameraLocationFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END UPDATE CAMERA_LOCATIONS ACTIONS -------- //

// ----------- UPDATE CAMERA_CONFIG ACTIONS -------- //
export interface IUpdateCameraConfig {
  type: AT.UPDATE_CAMERA_CONFIG;
}

export interface IUpdateCameraConfigSuccess {
  type: AT.UPDATE_CAMERA_CONFIG_SUCCESS;
}

export interface IUpdateCameraConfigFailure {
  type: AT.UPDATE_CAMERA_CONFIG_FAILURE;
}

export const updateCameraConfig: Redux.ActionCreator<IUpdateCameraConfig> = () => {
  return { type: AT.UPDATE_CAMERA_CONFIG };
};

export const updateCameraConfigSuccess: Redux.ActionCreator<IUpdateCameraConfigSuccess> = () => {
  return { type: AT.UPDATE_CAMERA_CONFIG_SUCCESS };
};

export const updateCameraConfigFailure: Redux.ActionCreator<IUpdateCameraConfigFailure> = () => {
  return { type: AT.UPDATE_CAMERA_CONFIG_FAILURE };
};

export const updateCameraConfigRequest = (cameraLocationId: string,
  cameraLocation: AdjustableSetting): ThunkResult<void> => {
  return async dispatch => {
    dispatch(updateCameraConfig());

    try {
      await cameraLocationsApi.updateCameraConfig(cameraLocationId,
        cameraLocation);
      dispatch(updateCameraConfigSuccess());
      toast.success("Successfully updated camera config!");
      dispatch(getCameraLocationRequest(cameraLocationId));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(updateCameraConfigFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END UPDATE CAMERA_LOCATIONS ACTIONS -------- //
// ----------- UPDATE CAMERA_SCHEDULE ACTIONS -------- //
export interface IUpdateCameraSchedule {
  type: AT.UPDATE_CAMERA_SCHEDULE;
}

export interface IUpdateCameraScheduleSuccess {
  type: AT.UPDATE_CAMERA_SCHEDULE_SUCCESS;
}

export interface IUpdateCameraScheduleFailure {
  type: AT.UPDATE_CAMERA_SCHEDULE_FAILURE;
}

export const updateCameraSchedule: Redux.ActionCreator<IUpdateCameraSchedule> = () => {
  return { type: AT.UPDATE_CAMERA_SCHEDULE };
};

export const updateCameraScheduleSuccess: Redux.ActionCreator<IUpdateCameraScheduleSuccess> = () => {
  return { type: AT.UPDATE_CAMERA_SCHEDULE_SUCCESS };
};

export const updateCameraScheduleFailure: Redux.ActionCreator<IUpdateCameraScheduleFailure> = () => {
  return { type: AT.UPDATE_CAMERA_SCHEDULE_FAILURE };
};

export const updateCameraScheduleRequest = (cameraLocationId: string,
  schedule: AdjustableSchedule): ThunkResult<void> => {
  return async dispatch => {
    dispatch(updateCameraSchedule());

    try {
      await cameraLocationsApi.updateCameraSchedule(cameraLocationId,
        schedule);
      dispatch(updateCameraScheduleSuccess());
      toast.success("Successfully updated camera schedule!");
      dispatch(getCameraLocationRequest(cameraLocationId));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(updateCameraScheduleFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END UPDATE CAMERA_SCHEDULE ACTIONS -------- //

// ----------- SUSPEND CAMERA_LOCATIONS ACTIONS -------- //
export interface ISuspendCameraLocation {
  type: AT.SUSPEND_CAMERA_LOCATION;
}

export interface ISuspendCameraLocationSuccess {
  type: AT.SUSPEND_CAMERA_LOCATION_SUCCESS;
}

export interface ISuspendCameraLocationFailure {
  type: AT.SUSPEND_CAMERA_LOCATION_FAILURE;
}

export const suspendCameraLocation: Redux.ActionCreator<ISuspendCameraLocation> = () => {
  return { type: AT.SUSPEND_CAMERA_LOCATION };
};

export const suspendCameraLocationSuccess: Redux.ActionCreator<ISuspendCameraLocationSuccess> = () => {
  return { type: AT.SUSPEND_CAMERA_LOCATION_SUCCESS };
};

export const suspendCameraLocationFailure: Redux.ActionCreator<ISuspendCameraLocationFailure> = () => {
  return { type: AT.SUSPEND_CAMERA_LOCATION_FAILURE };
};

export const suspendCameraLocationRequest = (cameraLocationId: string,
  siteId: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(suspendCameraLocation());

    try {
      await cameraLocationsApi.suspendCameraLocation(cameraLocationId);
      dispatch(suspendCameraLocationSuccess());
      toast.success("Successfully updated camera-location status!");
      dispatch(getCameraLocationRequest(cameraLocationId));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(suspendCameraLocationFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END SUSPEND CAMERA_LOCATIONS ACTIONS -------- //

// ----------- ARCHVIE CAMERA_LOCATIONS ACTIONS -------- //
export interface IArchiveCameraLocation {
  type: AT.ARCHIVE_CAMERA_LOCATION;
}

export interface IArchiveCameraLocationSuccess {
  type: AT.ARCHIVE_CAMERA_LOCATION_SUCCESS;
}

export interface IArchiveCameraLocationFailure {
  type: AT.ARCHIVE_CAMERA_LOCATION_FAILURE;
}

export const archiveCameraLocation: Redux.ActionCreator<IArchiveCameraLocation> = () => {
  return { type: AT.ARCHIVE_CAMERA_LOCATION };
};

export const archiveCameraLocationSuccess: Redux.ActionCreator<IArchiveCameraLocationSuccess> = () => {
  return { type: AT.ARCHIVE_CAMERA_LOCATION_SUCCESS };
};

export const archiveCameraLocationFailure: Redux.ActionCreator<IArchiveCameraLocationFailure> = () => {
  return { type: AT.ARCHIVE_CAMERA_LOCATION_FAILURE };
};

export const archiveCameraLocationRequest = (cameraLocationId: string,
  siteId: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(archiveCameraLocation());

    try {
      await cameraLocationsApi.archiveCameraLocation(cameraLocationId);
      dispatch(archiveCameraLocationSuccess());
      toast.success("Successfully archived camera-location!");
      dispatch(getCameraLocationsRequest(siteId));
      dispatch(goBack());
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(archiveCameraLocationFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END ARCHVIE CAMERA_LOCATIONS ACTIONS -------- //

// ----------- DELETE CAMERA_LOCATIONS ACTIONS -------- //
export interface IDeleteCameraLocation {
  type: AT.DELETE_CAMERA_LOCATION;
}

export interface IDeleteCameraLocationSuccess {
  type: AT.DELETE_CAMERA_LOCATION_SUCCESS;
}

export interface IDeleteCameraLocationFailure {
  type: AT.DELETE_CAMERA_LOCATION_FAILURE;
}

export const deleteCameraLocation: Redux.ActionCreator<IDeleteCameraLocation> = () => {
  return { type: AT.DELETE_CAMERA_LOCATION };
};

export const deleteCameraLocationSuccess: Redux.ActionCreator<IDeleteCameraLocationSuccess> = () => {
  return { type: AT.DELETE_CAMERA_LOCATION_SUCCESS };
};

export const deleteCameraLocationFailure: Redux.ActionCreator<IDeleteCameraLocationFailure> = () => {
  return { type: AT.DELETE_CAMERA_LOCATION_FAILURE };
};

export const deleteCameraLocationRequest = (cameraLocationId: string,
  siteId: string): ThunkResult<void> => {
  return async dispatch => {
    dispatch(deleteCameraLocation());

    try {
      await cameraLocationsApi.deleteCameraLocation(cameraLocationId);
      dispatch(deleteCameraLocationSuccess());
      toast.success("Successfully deleted camera-location!");
      dispatch(getCameraLocationsRequest(siteId));
      dispatch(goBack());
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(push("/logout"));
      }

      if (error.response && error.response.status === 403) {
        dispatch(push("/error", {
          code: "403",
          message: "You do not have permission to access this item."
        }));
      }

      dispatch(deleteCameraLocationFailure());
      toast.error(apiError(error));
    }
  };
};

// ----------- END DELETE CAMERA_LOCATIONS ACTIONS -------- //
