import * as Redux from "redux";
import * as AT from "./camera-locations.constants";
import { ICameraLocationStore } from "src/store/camera-locations/camera-locations.api";
import { CameraLocationActions } from "./camera-locations.actions";

const initialState: ICameraLocationStore = {
  currentCameraLocation: null,
  cameraLocationId: null,
  cameraLocations: [],
  cameraLocationsCount: 0,
  cameraLocationsTotals: {
    allTotal: 0,
    liveTotal: 0,
    suspendedTotal: 0,
    finishedTotal: 0,
    archivedTotal: 0
  },
  creatingCameraLocation: false,
  deletingCameraLocation: false,
  archivingCameraLocation: false,
  gettingCameraLocations: false,
  gettingCameraLocation: false,
  suspendingCameraLocation: false,
  updatingCameraLocation: false
};

export const cameraLocations: Redux.Reducer<ICameraLocationStore> = (state: ICameraLocationStore = initialState,
  action: CameraLocationActions): ICameraLocationStore => {
  switch (action.type) {
    case AT.SET_CAMERA_LOCATION:
      return { ...state };
    case AT.GET_CAMERA_LOCATION:
      return {
        ...state,
        cameraLocationId: null,
        gettingCameraLocation: true
      };

    case AT.GET_CAMERA_LOCATION_SUCCESS:
      return {
        ...state,
        cameraLocationId: action.cameraLocation.id,
        currentCameraLocation: action.cameraLocation || [],
        gettingCameraLocation: false
      };

    case AT.GET_CAMERA_LOCATION_FAILURE:
      return {
        ...state,
        gettingCameraLocation: false
      };

    case AT.GET_CAMERA_LOCATION_COUNTS:
      return {
        ...state,
        cameraLocationsTotals: {
          allTotal: 0,
          liveTotal: 0,
          suspendedTotal: 0,
          finishedTotal: 0,
          archivedTotal: 0
        }
      };

    case AT.GET_CAMERA_LOCATION_COUNTS_SUCCESS:
      return {
        ...state,
        cameraLocationsTotals: action.totals || {
          allTotal: 0,
          liveTotal: 0,
          suspendedTotal: 0,
          finishedTotal: 0,
          archivedTotal: 0
        }
      };

    case AT.GET_CAMERA_LOCATION_COUNTS_FAILURE: {
      return {
        ...state,
        cameraLocationsTotals: {
          allTotal: 0,
          liveTotal: 0,
          suspendedTotal: 0,
          finishedTotal: 0,
          archivedTotal: 0
        }
      };
    }

    case AT.GET_CAMERA_LOCATIONS:
      return {
        ...state,
        cameraLocations: [],
        gettingCameraLocations: true
      };
    case AT.GET_CAMERA_LOCATIONS_SUCCESS:
      
      return {
        ...state,
        cameraLocations: action.cameraLocations || [],
        cameraLocationsCount: action.count,
        gettingCameraLocations: false
      };
    case AT.GET_CAMERA_LOCATIONS_FAILURE:
      return {
        ...state,
        cameraLocationsCount: 0,
        gettingCameraLocations: false
      };

    // CREATE CAMERA_LOCATION
    case AT.CREATE_CAMERA_LOCATION:
      return {
        ...state,
        creatingCameraLocation: true
      };
    case AT.CREATE_CAMERA_LOCATION_SUCCESS:
      return {
        ...state,
        creatingCameraLocation: false
      };
    case AT.CREATE_CAMERA_LOCATION_FAILURE:
      return {
        ...state,
        creatingCameraLocation: false
      };

    // UPDATE CAMERA_LOCATION
    case AT.UPDATE_CAMERA_LOCATION:
      return {
        ...state,
        updatingCameraLocation: true
      };
    case AT.UPDATE_CAMERA_LOCATION_SUCCESS:
      return {
        ...state,
        updatingCameraLocation: false
      };
    case AT.UPDATE_CAMERA_LOCATION_FAILURE:
      return {
        ...state,
        updatingCameraLocation: false
      };

    // SUSPEND CAMERA_LOCATION
    case AT.SUSPEND_CAMERA_LOCATION:
      return {
        ...state,
        suspendingCameraLocation: true
      };
    case AT.SUSPEND_CAMERA_LOCATION_SUCCESS:
      return {
        ...state,
        suspendingCameraLocation: false
      };
    case AT.SUSPEND_CAMERA_LOCATION_FAILURE:
      return {
        ...state,
        suspendingCameraLocation: false
      };

    // ARCHIVE CAMERA_LOCATION
    case AT.ARCHIVE_CAMERA_LOCATION:
      return {
        ...state,
        archivingCameraLocation: true
      };
    case AT.ARCHIVE_CAMERA_LOCATION_SUCCESS:
      return {
        ...state,
        archivingCameraLocation: false
      };
    case AT.ARCHIVE_CAMERA_LOCATION_FAILURE:
      return {
        ...state,
        archivingCameraLocation: false
      };

    // DELETE CAMERA_LOCATION
    case AT.DELETE_CAMERA_LOCATION:
      return {
        ...state,
        deletingCameraLocation: true
      };
    case AT.DELETE_CAMERA_LOCATION_SUCCESS:
      return {
        ...state,
        deletingCameraLocation: false
      };
    case AT.DELETE_CAMERA_LOCATION_FAILURE:
      return {
        ...state,
        deletingCameraLocation: false
      };
    // PURGE CUSTOMER
    case AT.PURGE_CAMERA_LOCATIONS:
      return initialState;
    default:
      return state;
  }
};
