import styled from "styled-components";
import { styles } from "../../lib/styles";

export const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`;

export const MenuDrawerWrapper = styled.div`
  padding-right: 15px;
  position: relative;
`;

export const MenuDrawerButton = styled.div``;

interface IMenuWrapperProps {
  open: boolean;
}

export const MenuWrapper = styled.div<IMenuWrapperProps>`
  display: ${props => (props.open ? "block" : "none")};
`;

export const Menu = styled.div`
  right: 100%;
  margin: 0 10px;
  padding: 5px 10px;
  z-index: 99999;
  position: absolute;
  width: 240px;
  top: -30%;
  background: ${styles.secondaryDarkColor};
  border-radius: 0;
  padding-top: 25px;
  padding-bottom: 10px;
  border-right: 3px solid ${styles.primaryDarkColor};
  border-bottom: 3px solid ${styles.primaryDarkColor};
`;

export const MenuItem = styled.div`
  color: ${styles.secondaryTextColor};
  cursor: pointer;
  padding: 10px;
  margin-top: 0;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${styles.primaryAccentColor};
  }
`;

export const LineBreak = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;

  &::before {
    content: "Admin ";
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: ${styles.fadedTextColor};
  }

  &::after {
    content: " ";
    display: block;
    height: 1px;
    width: 100%;
    background: ${styles.fadedTextColor};
  }
`;
