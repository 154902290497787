import { ChevronUp, ChevronDown } from "lucide-react";
import React, { useState } from "react";

import Panel from "./SettingsPanelComponents";

export const IntervalDropdown: React.FC<{
  setting: {
    value: string;
    options: string[];
  };
  onChange: (value: string) => void;
}> = ({ setting, onChange }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <Panel.DropdownTrigger onClick={() => setOpen(!isOpen)}>
        <Panel.SettingValue>{setting.value}</Panel.SettingValue>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </Panel.DropdownTrigger>
       
      <Panel.DropdownContent style={{
        position: "absolute",
        top: "100%",
        right: 0,
        width: "130%"
      }} isOpen={isOpen}>
        {setting.options.map((option, index) => (
          <Panel.Option 
            onClick={() => {
              onChange(option);
              setOpen(false);
            }}
            selected={setting.value === option}
            key={index}
          >{option}</Panel.Option>
        ))}
      </Panel.DropdownContent>  

    </div>
  );
};
