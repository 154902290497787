import moment from "moment";
import React, {
  useEffect, useState, memo 
} from "react";
import getImageByType from "src/lib/getImageByType";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { IThumbnail } from "src/types/store/images";
import styled from "styled-components";
import Button from "../button";
import { styles } from "../../lib/styles";

interface IGridItemProps {
  cameraLocation: ICameraLocation;
  navigate: (path: string) => void;
  hideCamera: (cameraId: number) => void;
  showCameraLocationName: boolean;
}

const DashboardCameraContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const StyledImage = styled.div<{topOffset: number, src?: string}>`
  width: 100%;
  aspect-ratio: 16/9;
  min-height: 300px;
  object-fit: cover;
  background-image: url(${props => props.src});
  background-position: 50% ${props => props.topOffset}%;
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    height: 100%;
    aspect-ratio: auto;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  
  div {
    opacity: 0;
    transition: 0.2s ease;
  }

  &:hover div {
    opacity: 1;
  }

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1rem;
  gap: 1rem;
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }

  p {
    text-shadow: 0 0 8px rgba(0, 0, 0, 1);
    color: white;
    font-size: 1.2rem;
    font-weight: 900;
  }
`;

const TakenAtOverlay = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${styles.secondaryDarkColor};
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 4rem;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;

  font-size: 0.8rem;
  font-weight: 600;
  white-space: nowrap;
`;

interface ICameraImageProps {
  imageUrl?: string;
  topOffset: number;
}

// Helper to extract base URL and expiry from signed URL
const parseSignedUrl = (url?: string) => {
  if (!url) return {
    baseUrl: "",
    expiresAt: 0 
  };

  try {
    const urlObj = new URL(url);
    const expiresParam = urlObj.searchParams.get("X-Amz-Expires");
    const dateParam = urlObj.searchParams.get("X-Amz-Date");
    // Get expiry time by parsing X-Amz-Date and adding X-Amz-Expires seconds
    let expiresAt = 0;

    if (dateParam && expiresParam) {
      const dateStr = dateParam.slice(0, 8); // YYYYMMDD format
      const timeStr = dateParam.slice(9, 15); // HHMMSS format
      const date = moment(`${dateStr} ${timeStr}`, "YYYYMMDD HHmmss").valueOf();

      expiresAt = date + (parseInt(expiresParam, 10) * 1000);
    }

    // Get just the path portion of the URL (everything after the hostname and before the query string)
    const pathOnly = urlObj.pathname;

    return {
      baseUrl: pathOnly,
      expiresAt 
    };
  } catch {
    return {
      baseUrl: "",
      expiresAt: 0 
    };
  }
};

const CameraImage = memo(({ imageUrl, topOffset }: ICameraImageProps) => {
  if (!imageUrl) return <div></div>;

  return <StyledImage src={imageUrl} topOffset={topOffset} />;
}, (prevProps, nextProps) => {
  if (prevProps.topOffset !== nextProps.topOffset) return false;

  if (!prevProps.imageUrl && !nextProps.imageUrl) return true;

  if (!prevProps.imageUrl || !nextProps.imageUrl) return false;

  const prev = parseSignedUrl(prevProps.imageUrl);
  const next = parseSignedUrl(nextProps.imageUrl);

  // If base URLs are different, we should re-render
  if (prev.baseUrl !== next.baseUrl) return false;

  // If the URL is about to expire (within 5 minutes), allow re-render
  const fiveMinutesFromNow = Date.now() + 5 * 60 * 1000;

  if (prev.expiresAt < fiveMinutesFromNow) return false;

  // Otherwise, prevent re-render
  return true;
});

CameraImage.displayName = "CameraImage";

const DashboardCamera = memo(({
  cameraLocation, navigate, hideCamera, showCameraLocationName
}: IGridItemProps) => {
  const [thumbnail, setThumbnail] = useState<IThumbnail | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      const thumbnail = await getImageByType("tablet", cameraLocation.latest_image?.thumbnails || []);

      setThumbnail(thumbnail);
    };

    fetchImage();
  }, [cameraLocation.latest_image?.thumbnails]);

  return (
    <DashboardCameraContainer>
      <CameraImage imageUrl={thumbnail?.s3_url} topOffset={cameraLocation.offset_top} />
      <Overlay>
        {showCameraLocationName ? <p>{cameraLocation.site?.name} • {cameraLocation.name}</p> : <p>{cameraLocation.site?.name}</p>}
        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem" 
        }}>
          <Button text="Hide" clickHandler={() => hideCamera(cameraLocation.id)} />
          <Button text="View" clickHandler={() => navigate(`/customer/${cameraLocation.site?.customer?.id}/site/${cameraLocation.site_id}/camera/${cameraLocation.uuid}/images`)} />
        </div>
      </Overlay>
      <TakenAtOverlay>
        <span>{moment(cameraLocation.latest_image?.taken_at).format("dddd DD MMM YYYY")}</span>
        <span>{moment(cameraLocation.latest_image?.taken_at).format("HH:mm")}</span>
      </TakenAtOverlay>
    </DashboardCameraContainer>
  );
});

DashboardCamera.displayName = "DashboardCamera";

export default DashboardCamera;
