import { IStore } from "../../types/store/store";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import _ from "lodash";

export const cameraLocationGetByUUID = (state: IStore,
  cameraLocationUUID: string): ICameraLocation | null => {
  if (cameraLocationUUID) {
    const cameraLocation = _.find(state.cameraLocations.cameraLocations,
      c => c.uuid === cameraLocationUUID);

    return cameraLocation ? cameraLocation : null;
  } else {
    return null;
  }
};
