import React, { forwardRef } from "react";
import { connect } from "react-redux";
import Redux from "redux";
import { ThunkDispatch } from "redux-thunk";
import { styles } from "src/lib/styles";
import { updateDashboardCamera } from "src/store/users/users.actions";
import { IStore } from "src/types/store/store";
import {
  IDashboardCamera, IUser, IUserCameraAssociations 
} from "src/types/store/users";
import styled from "styled-components";
import { CheckContainer } from "../check-list-menu/check-list-menu";
import Icon from "../icon";

const CamerasDropdownContainer = styled.div`
  position: relative;
  z-index: 9999;
  width: 100%;
  
  box-sizing: border-box;
  
  @media (min-width: 768px) {
    width: auto;
    padding-left: 1rem;
    position: absolute;
    top: 0;
    left: 100%;
  }
`;

const StyledDropdown = styled.div`
  background-color: ${styles.primaryDarkColor};
  border-radius: 0.5rem;
  border: 1px solid ${styles.primaryAccentColor};
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
`;

const DashboardCamerasDropdown = forwardRef<HTMLDivElement, {isOpen: boolean, onClose: () => void}>(({ isOpen, onClose }, ref) => {
  return (
    <CamerasDropdownContainer ref={ref} onMouseLeave={() => {
      if (window.innerWidth > 768) {
        onClose();
      }
    }}>
      {isOpen && (
        <StyledDropdown>
          <InnerDropdown />
        </StyledDropdown>
      )}
    </CamerasDropdownContainer>
  );
});

DashboardCamerasDropdown.displayName = "DashboardCamerasDropdown";

interface IProps {
  dashboardCameras: IDashboardCamera[];
  user: IUser;
  userCameras: IUserCameraAssociations[];
}
interface IDispatchProps {
  updateDashboardCamera: (camera: IDashboardCamera) => void;
}

const InnerDropdownComponent: React.FC<IProps & IDispatchProps> = ({
  dashboardCameras, userCameras, updateDashboardCamera, user 
}) => {
  return (
    <div>
      {dashboardCameras.map(camera => {
        const userCamera = userCameras?.find(userCamera => userCamera.id === camera.camera_location_id);
        
        if (!userCamera) return null;

        return (
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "0.5rem",
            cursor: "pointer"
          }} 
          key={camera.camera_location_id}
          onClick={() => {
            updateDashboardCamera({
              ...camera,
              show: !camera.show
            });
          }}
          >
            <CheckContainer className={camera.show ? "checked" : "unchecked"}>
              <Icon icon="tick" />
            </CheckContainer>
            <div style={{
              display: "flex",
              flexDirection: "column" 
            }}>
              {user?.["custom:role"] === "admin" && <p style={{ fontSize: "0.8rem" }}>{userCamera.site.customer.name}</p>}
              <p style={{ whiteSpace: "nowrap" }}>{userCamera.site.name} • {userCamera.name} {user?.["custom:role"] === "admin" && `• ${userCamera.job_ref}`}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: IStore) => ({
  dashboardCameras: state.users.dashboardCameras,
  userCameras: state.users.userCameras,
  user: state.users.currentUser
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IDispatchProps => {
  return { updateDashboardCamera: (camera: IDashboardCamera) => dispatch(updateDashboardCamera(camera)) };
};

const InnerDropdown = connect(mapStateToProps, mapDispatchToProps)(InnerDropdownComponent);

export default DashboardCamerasDropdown;