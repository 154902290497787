import logger from "src/lib/logger";
import axios from "axios";
import Headers from "../../lib/auth";
import _ from "lodash";

import { PaginatedParams, PaginatedData } from "../../types/pagination/index";
import * as qs from "qs";
import { ISite } from "src/store/sites/sites.api";
import { CameraTotals } from "./camera-locations.actions";
import { IImage } from "src/types/store/images";

export const getCameraLocation = async (
  cameraLocationId: string
): Promise<ICameraLocation> => {
  try {
    const headers = await Headers("admin");

    const result = await axios({
      headers,
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/admin/camera-location/${cameraLocationId}`
    });

    logger.info("GET CAMERA_LOCATION", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET CAMERA_LOCATION ERROR", error);
    throw error;
  }
};

export const refreshLatestImage = async (
  cameraLocationId: number
): Promise<any> => {
  try {
    const headers = await Headers("admin");

    const result = await axios({
      headers,
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/admin/camera-location/${cameraLocationId}/refresh-latest-image`
    });

    logger.info("REFRESH_LATEST_IMAGE", result);

    return result.data.data;
  } catch (error) {
    logger.error("REFRESH_LATEST_IMAGE ERROR", error);
    throw error;
  }
};

export const getCameraLocationByUUID = async (
  cameraLocationUUID: string,
  role = "client"
): Promise<ICameraLocation> => {
  try {
    const headers = await Headers(role);

    const result = await axios({
      headers,
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/${role}/camera-location/uuid/${cameraLocationUUID}`
    });

    logger.info("GET CAMERA_LOCATION BY UUID", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET CAMERA_LOCATION BY UUID ERROR", error);
    throw error;
  }
};

export const getCameraLocationCounts = async (siteId?: string): Promise<CameraTotals> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "GET",
      url: siteId ? `${process.env.REACT_APP_API_URL}/admin/camera-locations/site/${siteId}/count` : `${process.env.REACT_APP_API_URL}/admin/camera-locations/count`
    });

    logger.info("GET CAMERA_LOCATION_COUNTS", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET CAMERA_LOCATION_COUNTS ERROR", error);
    throw error;
  }
};

export const getCameraLocations = async (
  siteId: string,
  role = "client",
  pagination?: PaginatedParams<ICameraLocation>,
  status = "active",
  is_finished = 0
): Promise<PaginatedData<ICameraLocation>> => {
  try {
    const headers = await Headers(role);

    const result = await axios({
      headers,
      method: "GET",
      params: {
        pagination,
        filters: {
          status,
          is_finished 
        }
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
      url: `${process.env.REACT_APP_API_URL}/${role}/camera-locations/site/${siteId}?paginated=true`
    });

    logger.info("GET CAMERA_LOCATIONS", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET CAMERA_LOCATIONS ERROR", error);
    throw error;
  }
};

export const getAllCameraLocations = async (
  pagination: PaginatedParams<ICameraLocation>,
  filters: Partial<ICameraLocation>
): Promise<PaginatedData<ICameraLocation>> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "GET",
      params: {
        pagination,
        filters
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
      url: `${process.env.REACT_APP_API_URL}/admin/camera-locations?paginated=true`
    });

    logger.info("GET ALL CAMERA_LOCATIONS", result);

    return result.data.data;
  } catch (error) {
    logger.error("GET ALL  CAMERA_LOCATIONS ERROR", error);
    throw error;
  }
};

export const createCameraLocation = async (
  cameraLocation: ICameraLocation
): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      data: cameraLocation,
      headers,
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/admin/camera-location`
    });

    logger.info("CREATE CAMERA_LOCATION", result);

    return result.data.data;
  } catch (error) {
    logger.error("CREATE CAMERA_LOCATION ERROR", error);
    throw error;
  }
};

export const updateCameraLocation = async (
  cameraLocationId: string,
  cameraLocation: ICameraLocation
): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      data: cameraLocation,
      headers,
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/admin/camera-location/${cameraLocationId}`
    });

    logger.info("UPDATE CAMERA_LOCATION", result);

    return result.data.data;
  } catch (error) {
    logger.error("UPDATE CAMERA_LOCATION ERROR", error);
    throw error;
  }
};

export const suspendCameraLocation = async (
  cameraLocationId: string
): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/admin/camera-location/status/${cameraLocationId}`
    });

    logger.info("SUSPEND CAMERA_LOCATION", result);

    return result.data.data;
  } catch (error) {
    logger.error("SUSPEND CAMERA_LOCATION ERROR", error);
    throw error;
  }
};

export const archiveCameraLocation = async (
  cameraLocationId: string
): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/admin/camera-location/${cameraLocationId}`
    });

    logger.info("ARCHIVE CAMERA_LOCATION", result);

    return result.data.data;
  } catch (error) {
    logger.error("ARCHIVE CAMERA_LOCATION ERROR", error);
    throw error;
  }
};

export const deleteCameraLocation = async (
  cameraLocationId: string
): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      headers,
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/admin/camera-location/${cameraLocationId}?method=delete`
    });

    logger.info("DELETE CAMERA_LOCATION", result);

    return result.data.data;
  } catch (error) {
    logger.error("DELETE CAMERA_LOCATION ERROR", error);
    throw error;
  }
};

export const updateCameraConfig = async (
  cameraLocationId: string,
  cameraConfig: AdjustableSetting
): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      data: cameraConfig,
      headers,
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/admin/camera-location/${cameraLocationId}/config`
    });

    logger.info("UPDATE CAMERA_CONFIG", result);

    return result.data.data;
  } catch (error) {
    logger.error("UPDATE CAMERA_CONFIG ERROR", error);
    throw error;
  }
};

export const updateCameraSchedule = async (
  cameraLocationId: string,
  schedule: AdjustableSchedule
): Promise<any> => {
  try {
    const headers = await Headers();

    const result = await axios({
      data: schedule,
      headers,
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/admin/camera-location/${cameraLocationId}/schedule`
    });

    logger.info("UPDATE CAMERA_SCHEDULE", result);

    return result.data.data;
  } catch (error) {
    logger.error("UPDATE CAMERA_SCHEDULE ERROR", error);
    throw error;
  }
};

/**
 * Interface for the camera locations reducer
 */
export interface ICameraLocationStore {
  cameraLocationId: number | null;
  currentCameraLocation: ICameraLocation | null;
  cameraLocationsCount: number;
  cameraLocations: ICameraLocation[];
  cameraLocationsTotals: CameraTotals;
  gettingCameraLocations: boolean;
  gettingCameraLocation: boolean;
  updatingCameraLocation: boolean;
  creatingCameraLocation: boolean;
  suspendingCameraLocation: boolean;
  deletingCameraLocation: boolean;
  archivingCameraLocation: boolean;
}

export enum CameraLocationHealthStatus {
  Healthy = "healthy",
  LowVoltage = "low_voltage",
  LowDiskSpace = "low_disk_space",
  VeryLowDiskSpace = "very_low_disk_space",
  NoImages = "no_images"
}

export interface ICameraLocation {
  id: number;
  uuid: string;
  name: string;
  job_ref: string;
  hardware_ref: string | null;
  is_voltage_display?: 0 | 1;
  startDate: string;
  endDate: string;
  bucketKey: string;
  frequency: string;
  status: string;
  health_status: CameraLocationHealthStatus;
  site_id: number;
  orientation: string;
  longitude: number;
  latitude: number;
  timezone: string;
  public: 0 | 1;
  is_finished: 0 | 1;
  latest_image?: Partial<IImage>;
  today_first_capture?: Date | string;
  latest_metric?: IFolderSizeMetric;
  site?: ISite;
  delay: number;
  ip_camera_url?: string | null;
  search_terms?: string;
  video_settings: {
    start_time: string;
    end_time: string;
    enabled: boolean;
    days_of_week?: number[];
    resize?: VideoResize;
    crop?: VideoCrop;
    watermark?: {
      s3Key: string;
      position: WaterMarkPosition;
    };
    bumper?: {
      prepend?: string;
      append?: string;
    };
    audio?: AudioSettings;
  };
  
  mask_settings: {
    enabled: boolean;
    s3Path: string;
    blurAmount: number;
  } | null;
  live_image_settings: LiveImageSettings | null;
  watermark_settings: WatermarkSettings | null;
  // Latest image stats
  latest_voltage: number | null;
  latest_temperature: number | null;
  latest_disk_space: number | null;
  // Notification settings
  minimum_image_frequency: number;
  minimum_healthy_voltage: number;
  notifications_muted: number;
  notifications_muted_until: string | null;
  // Whether low voltage notifications are muted, independently of no image notifications
  voltage_notifications_muted: number;
  offset_top: number;

  camera_on?: 0 | 1;
  camera_model?: string;
  camera_serial?: string;
  lens_model?: string;
  lens_serial?: string;
  heater_on?: 0 | 1;
  camera_lid_open?: 0 | 1;

  desired_camera_on?: 0 | 1;
  desired_heater_on?: 0 | 1;
  desired_camera_lid_open?: 0 | 1;
  desired_image_format?: string;
  desired_iso?: number;
  desired_aperture?: number;
  desired_shutter?: string;
  desired_exposure_comp?: number;
  desired_white_balance?: string;
  schedule_json?: string | AdjustableSchedule;
}

export enum Day {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday"
}

export interface AdjustableSchedule {
  [Day.Monday]: ScheduleForDay;
  [Day.Tuesday]: ScheduleForDay;
  [Day.Wednesday]: ScheduleForDay;
  [Day.Thursday]: ScheduleForDay;
  [Day.Friday]: ScheduleForDay;
  [Day.Saturday]: ScheduleForDay;
  [Day.Sunday]: ScheduleForDay;
}

export interface ScheduleForDay {
  enabled: boolean;
  start_time: string;
  end_time: string;
  interval_value: number;
  interval_unit: "seconds" |"minutes" | "hours";
}

export interface AdjustableSetting {
  desired_camera_on?: 0 | 1;
  desired_heater_on?: 0 | 1;
  desired_camera_lid_open?: 0 | 1;
  desired_image_format?: string;
  desired_iso?: number;
  desired_aperture?: number;
  desired_shutter?: string;
  desired_exposure_comp?: number;
  desired_white_balance?: string;
}

export interface WatermarkSettings {
  s3Key: string;
  opacity: number;
}

export interface VideoResize {
  height: number;
  width: number;
}

export interface VideoCrop {
  height: number;
  width: number;
  offsetX: number;
  offsetY: number;
}

export interface AudioSettings {
  s3Bucket: string;
  s3Uri: string;
}

export type WaterMarkPosition = "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
export interface IFolderSizeMetric {
  id: number;
  size: number;
  camera_location_id: number;
  created_at: Date;
}

export interface LiveImageSettings {
  crop: {
    height: number;
    width: number;
    offsetX: number;
    offsetY: number;
  };
  resize: {
    height?: number;
    width?: number;
  };
  client: OverlaySettings | null;
  admin: OverlaySettings | null;
}

export type OverlaySettings = {
  [key in SettingName]?: OverlaySetting
};

export enum SettingName {
  timestamp = "timestamp",
  hardwareRef = "hardware_ref",
  text = "text"
}

export interface OverlaySetting {
  message?: string;
  enabled: boolean;
  position: ImagePosition;
}

export enum ImagePosition {
  bottomRight = "bottomRight",
  bottomLeft = "bottomLeft",
  topLeft = "topLeft",
  topRight = "topRight",
  topCenter = "topCenter",
  bottomCenter = "bottomCenter"
}
