import {
  ICameraLocation, AdjustableSchedule, Day, ScheduleForDay 
} from "src/store/camera-locations/camera-locations.api";

export type SettingsAction = {
  type: "UPDATE_SETTING";
  key: Day;
  value: ScheduleForDay;
} | {
  type: "UPDATE_ALL_SETTINGS";
  value: ScheduleForDay;
} | {
  type: "RESET_TO_CAMERA_LOCATION";
  cameraLocation: ICameraLocation | null;
};

export const initalState: AdjustableSchedule = {
  Monday: {
    enabled: true,
    start_time: "09:00",
    end_time: "16:00",
    interval_value: 5,
    interval_unit: "minutes"
  },
  Tuesday: {
    enabled: true,
    start_time: "09:00",
    end_time: "16:00",
    interval_value: 5,
    interval_unit: "minutes"
  },
  Wednesday: {
    enabled: true,
    start_time: "09:00",
    end_time: "16:00",
    interval_value: 5,
    interval_unit: "minutes"
  },
  Thursday: {
    enabled: true,
    start_time: "09:00",
    end_time: "16:00",
    interval_value: 5,
    interval_unit: "minutes"  
  },
  Friday: {
    enabled: true,
    start_time: "09:00",
    end_time: "16:00",
    interval_value: 5,
    interval_unit: "minutes"  
  },
  Saturday: {
    enabled: true,
    start_time: "09:00",
    end_time: "16:00",
    interval_value: 5,
    interval_unit: "minutes"
  },
  Sunday: {
    enabled: true,
    start_time: "09:00",
    end_time: "16:00",
    interval_value: 5,
    interval_unit: "minutes"
  }
};

export const scheduleReducer = (state: AdjustableSchedule, action: SettingsAction): AdjustableSchedule => {
  switch (action.type) {
    case "UPDATE_SETTING":
      return {
        ...state,
        [action.key]: action.value
      };
    case "UPDATE_ALL_SETTINGS":
      return Object.keys(state).reduce((acc, key) => {
        acc[key as Day] = action.value;

        return acc;
      }, {} as AdjustableSchedule);
    case "RESET_TO_CAMERA_LOCATION": {
      if (!action.cameraLocation?.schedule_json) return state;

      const schedule = typeof action.cameraLocation.schedule_json === "string"
        ? JSON.parse(action.cameraLocation.schedule_json) as AdjustableSchedule
        : action.cameraLocation.schedule_json;

      return schedule; 
    }
    default:
      return state;
  }
};