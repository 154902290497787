export const PURGE_CAMERA_LOCATIONS = "@camera-location/PURGE_CAMERA_LOCATIONS";
export type PURGE_CAMERA_LOCATIONS = typeof PURGE_CAMERA_LOCATIONS;

export const SET_CAMERA_LOCATION = "@camera-location/SET_CAMERA_LOCATION";
export type SET_CAMERA_LOCATION = typeof SET_CAMERA_LOCATION;

export const GET_CAMERA_LOCATION = "@camera-location/GET_CAMERA_LOCATION";
export type GET_CAMERA_LOCATION = typeof GET_CAMERA_LOCATION;
export const GET_CAMERA_LOCATION_SUCCESS =
  "@camera-location/GET_CAMERA_LOCATION_SUCCESS";
export type GET_CAMERA_LOCATION_SUCCESS = typeof GET_CAMERA_LOCATION_SUCCESS;
export const GET_CAMERA_LOCATION_FAILURE =
  "@camera-location/GET_CAMERA_LOCATION_FAILURE";
export type GET_CAMERA_LOCATION_FAILURE = typeof GET_CAMERA_LOCATION_FAILURE;

export const GET_CAMERA_LOCATION_COUNTS = "@camera-location/GET_CAMERA_LOCATION_COUNTS";
export type GET_CAMERA_LOCATION_COUNTS = typeof GET_CAMERA_LOCATION_COUNTS;
export const GET_CAMERA_LOCATION_COUNTS_SUCCESS =
  "@camera-location/GET_CAMERA_LOCATION_COUNTS_SUCCESS";
export type GET_CAMERA_LOCATION_COUNTS_SUCCESS = typeof GET_CAMERA_LOCATION_COUNTS_SUCCESS;
export const GET_CAMERA_LOCATION_COUNTS_FAILURE =
  "@camera-location/GET_CAMERA_LOCATION_COUNTS_FAILURE";
export type GET_CAMERA_LOCATION_COUNTS_FAILURE = typeof GET_CAMERA_LOCATION_COUNTS_FAILURE;

export const GET_CAMERA_LOCATIONS = "@camera-location/GET_CAMERA_LOCATIONS";
export type GET_CAMERA_LOCATIONS = typeof GET_CAMERA_LOCATIONS;
export const GET_CAMERA_LOCATIONS_SUCCESS =
  "@camera-location/GET_CAMERA_LOCATIONS_SUCCESS";
export type GET_CAMERA_LOCATIONS_SUCCESS = typeof GET_CAMERA_LOCATIONS_SUCCESS;
export const GET_CAMERA_LOCATIONS_FAILURE =
  "@camera-location/GET_CAMERA_LOCATIONS_FAILURE";
export type GET_CAMERA_LOCATIONS_FAILURE = typeof GET_CAMERA_LOCATIONS_FAILURE;

export const CREATE_CAMERA_LOCATION = "@camera-location/CREATE_CAMERA_LOCATION";
export type CREATE_CAMERA_LOCATION = typeof CREATE_CAMERA_LOCATION;
export const CREATE_CAMERA_LOCATION_SUCCESS =
  "@camera-location/CREATE_CAMERA_LOCATION_SUCCESS";
export type CREATE_CAMERA_LOCATION_SUCCESS = typeof CREATE_CAMERA_LOCATION_SUCCESS;
export const CREATE_CAMERA_LOCATION_FAILURE =
  "@camera-location/CREATE_CAMERA_LOCATION_FAILURE";
export type CREATE_CAMERA_LOCATION_FAILURE = typeof CREATE_CAMERA_LOCATION_FAILURE;

export const UPDATE_CAMERA_LOCATION = "@camera-location/UPDATE_CAMERA_LOCATION";
export type UPDATE_CAMERA_LOCATION = typeof UPDATE_CAMERA_LOCATION;
export const UPDATE_CAMERA_LOCATION_SUCCESS =
  "@camera-location/UPDATE_CAMERA_LOCATION_SUCCESS";
export type UPDATE_CAMERA_LOCATION_SUCCESS = typeof UPDATE_CAMERA_LOCATION_SUCCESS;
export const UPDATE_CAMERA_LOCATION_FAILURE =
  "@camera-location/UPDATE_CAMERA_LOCATION_FAILURE";
export type UPDATE_CAMERA_LOCATION_FAILURE = typeof UPDATE_CAMERA_LOCATION_FAILURE;

export const REFRESH_LATEST_IMAGE = "@camera-location/REFRESH_LATEST_IMAGE";
export type REFRESH_LATEST_IMAGE = typeof REFRESH_LATEST_IMAGE;
export const REFRESH_LATEST_IMAGE_SUCCESS =
  "@camera-location/REFRESH_LATEST_IMAGE_SUCCESS";
export type REFRESH_LATEST_IMAGE_SUCCESS = typeof REFRESH_LATEST_IMAGE_SUCCESS;
export const REFRESH_LATEST_IMAGE_FAILURE =
  "@camera-location/REFRESH_LATEST_IMAGE_FAILURE";
export type REFRESH_LATEST_IMAGE_FAILURE = typeof REFRESH_LATEST_IMAGE_FAILURE;

export const SUSPEND_CAMERA_LOCATION =
  "@camera-location/SUSPEND_CAMERA_LOCATION";
export type SUSPEND_CAMERA_LOCATION = typeof SUSPEND_CAMERA_LOCATION;
export const SUSPEND_CAMERA_LOCATION_SUCCESS =
  "@camera-location/SUSPEND_CAMERA_LOCATION_SUCCESS";
export type SUSPEND_CAMERA_LOCATION_SUCCESS = typeof SUSPEND_CAMERA_LOCATION_SUCCESS;
export const SUSPEND_CAMERA_LOCATION_FAILURE =
  "@camera-location/SUSPEND_CAMERA_LOCATION_FAILURE";
export type SUSPEND_CAMERA_LOCATION_FAILURE = typeof SUSPEND_CAMERA_LOCATION_FAILURE;

export const ARCHIVE_CAMERA_LOCATION = "@camera-location/ARCHIVE_CAMERA_LOCATION";
export type ARCHIVE_CAMERA_LOCATION = typeof ARCHIVE_CAMERA_LOCATION;
export const ARCHIVE_CAMERA_LOCATION_SUCCESS =
  "@camera-location/ARCHIVE_CAMERA_LOCATION_SUCCESS";
export type ARCHIVE_CAMERA_LOCATION_SUCCESS = typeof ARCHIVE_CAMERA_LOCATION_SUCCESS;
export const ARCHIVE_CAMERA_LOCATION_FAILURE =
  "@camera-location/ARCHIVE_CAMERA_LOCATION_FAILURE";
export type ARCHIVE_CAMERA_LOCATION_FAILURE = typeof ARCHIVE_CAMERA_LOCATION_FAILURE;

export const DELETE_CAMERA_LOCATION = "@camera-location/DELETE_CAMERA_LOCATION";
export type DELETE_CAMERA_LOCATION = typeof DELETE_CAMERA_LOCATION;
export const DELETE_CAMERA_LOCATION_SUCCESS =
  "@camera-location/DELETE_CAMERA_LOCATION_SUCCESS";
export type DELETE_CAMERA_LOCATION_SUCCESS = typeof DELETE_CAMERA_LOCATION_SUCCESS;
export const DELETE_CAMERA_LOCATION_FAILURE =
  "@camera-location/DELETE_CAMERA_LOCATION_FAILURE";
export type DELETE_CAMERA_LOCATION_FAILURE = typeof DELETE_CAMERA_LOCATION_FAILURE;

export const UPDATE_CAMERA_CONFIG = "@camera-location/UPDATE_CAMERA_CONFIG";
export type UPDATE_CAMERA_CONFIG = typeof UPDATE_CAMERA_CONFIG;
export const UPDATE_CAMERA_CONFIG_SUCCESS =
  "@camera-location/UPDATE_CAMERA_CONFIG_SUCCESS";
export type UPDATE_CAMERA_CONFIG_SUCCESS = typeof UPDATE_CAMERA_CONFIG_SUCCESS;
export const UPDATE_CAMERA_CONFIG_FAILURE =
  "@camera-location/UPDATE_CAMERA_CONFIG_FAILURE";
export type UPDATE_CAMERA_CONFIG_FAILURE = typeof UPDATE_CAMERA_CONFIG_FAILURE;

export const UPDATE_CAMERA_SCHEDULE = "@camera-location/UPDATE_CAMERA_SCHEDULE";
export type UPDATE_CAMERA_SCHEDULE = typeof UPDATE_CAMERA_SCHEDULE;
export const UPDATE_CAMERA_SCHEDULE_SUCCESS =
  "@camera-location/UPDATE_CAMERA_SCHEDULE_SUCCESS";
export type UPDATE_CAMERA_SCHEDULE_SUCCESS = typeof UPDATE_CAMERA_SCHEDULE_SUCCESS;
export const UPDATE_CAMERA_SCHEDULE_FAILURE =
  "@camera-location/UPDATE_CAMERA_SCHEDULE_FAILURE";
export type UPDATE_CAMERA_SCHEDULE_FAILURE = typeof UPDATE_CAMERA_SCHEDULE_FAILURE;