import React from "react";

import {
  getIconForInfoPill, IInfoPill, InfoPillLabel, 
  IOverlayProps
} from "./overlay-utils";
import { OverlayWrapper } from "./OverlayComponent";
import { InfoPill } from "./OverlayComponent";

export const BottomLeftOverlay: React.FC<IOverlayProps> = ({
  isOverlayActive,
  activeImage,
  cameraLocation,
  isAdmin,
  hidesOnDesktopDrag,
  alwaysOn
}) => {
  const info: IInfoPill[] = [
    {
      label: InfoPillLabel.Aperture,
      visibleToAdmin: isAdmin,
      visibleToClient: false,
      value: activeImage?.aperture,
      display_text: activeImage?.aperture ? activeImage?.aperture : "N/A"
    },
    {
      label: InfoPillLabel.Shutter,
      visibleToAdmin: isAdmin,
      visibleToClient: false,
      value: activeImage?.shutter,
      display_text: activeImage?.shutter ? activeImage?.shutter : "N/A"
    },
    {
      label: InfoPillLabel.ISO,
      visibleToAdmin: isAdmin,
      visibleToClient: false,
      value: activeImage?.iso,
      display_text: activeImage?.iso ? `ISO ${activeImage?.iso}` : "N/A"
    },
    {
      label: InfoPillLabel.FocusMode,
      visibleToAdmin: isAdmin,
      visibleToClient: false,
      value: activeImage?.focus_mode,
      display_text: activeImage?.focus_mode ? activeImage?.focus_mode : "N/A"
    },
    {
      label: InfoPillLabel.ExposureMode,
      visibleToAdmin: isAdmin,
      visibleToClient: false,
      value: activeImage?.exposure_mode,
      display_text: activeImage?.exposure_mode ? activeImage?.exposure_mode : "N/A"
    },
    {
      label: InfoPillLabel.Dimensions,
      visibleToAdmin: isAdmin,
      visibleToClient: false,
      value: activeImage?.width,
      display_text: `${activeImage?.width}px × ${activeImage?.height}px`
    }
  ].filter(item => isAdmin ? item.visibleToAdmin || item.visibleToClient : item.visibleToClient) as IInfoPill[];
  
  return (
    <OverlayWrapper 
      position={"bottom-left"} 
      style={{ opacity: alwaysOn ? 1 : hidesOnDesktopDrag && !isOverlayActive ? 0 : 1 }}
    >
      {info.map(item => (
        <InfoPill label={item.label} key={item.label}>
          {getIconForInfoPill(item)}
          <span>{item.display_text}</span>
        </InfoPill>
      ))}
    </OverlayWrapper>
  );
};
