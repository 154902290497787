/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef } from "react";

import "mapbox-gl/dist/mapbox-gl.css";
import styled from "styled-components";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";

import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker"; // Load worker code separately with worker-loader

import MapCamera from "src/components/dashboard/MapCamera";
import ReactDOM from "react-dom";

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  flex: 1;
`;

interface IMapProps {
  cameraLocations: ICameraLocation[];
  navigate: (path: string) => void;
}

export const Map = ({ cameraLocations, navigate }: IMapProps) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  // Ensure we're only using camera Locations with a valid lat & long
  cameraLocations = cameraLocations.filter(l => !!Number(l.latitude) && !!Number(l.longitude));
  mapboxgl.workerClass = MapboxWorker;

  useEffect(() => {
    // TO MAKE THE MAP APPEAR YOU MUST
    // ADD YOUR ACCESS TOKEN FROM
    // https://account.mapbox.com
    mapboxgl.accessToken = "pk.eyJ1IjoiaW50ZXJ2YWx0diIsImEiOiJjbTZnb3F4YTkwM3A5MmlzZXg0NXU2b2R6In0.GVjopdM6fN5HWcRdc9ktuw";

    const getCentreCoordsByCameraLocations = (cameraLocations: ICameraLocation[]): [number, number] => {
      const latitudes = cameraLocations.map(cameraLocation => cameraLocation.latitude);
      const longitudes = cameraLocations.map(cameraLocation => cameraLocation.longitude);
      
      if (latitudes.length > 0 && longitudes.length > 0) {
        return [longitudes.reduce((a, b) => a + b, 0) / longitudes.length, latitudes.reduce((a, b) => a + b, 0) / latitudes.length];
      } else {
        const longitude = -2.596654;
        const latitude = 51.455235;
        
        // default to Bristol city centre, UK:
        return [longitude, latitude];
      }
    };

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current!,
      style: "mapbox://styles/samgravitywell/cm6glm8e000em01qx900jaf42",
      center: getCentreCoordsByCameraLocations(cameraLocations),
      zoom: 12,
      attributionControl: false,
      accessToken: "pk.eyJ1IjoiaW50ZXJ2YWx0diIsImEiOiJjbTZnb3F4YTkwM3A5MmlzZXg0NXU2b2R6In0.GVjopdM6fN5HWcRdc9ktuw"
    });

    // Create bounds that will contain all markers
    const bounds = new mapboxgl.LngLatBounds();
    
    // Extend bounds to include each camera location
    cameraLocations.forEach(location => {
      if (location.longitude && location.latitude) {
        bounds.extend([location.longitude, location.latitude]);
      }
    });

    // Fit the map to the bounds with some padding
    mapRef.current?.fitBounds(bounds, {
      padding: 50,
      maxZoom: 17
    });

    cameraLocations.forEach(cameraLocation => {
      // Create a DOM element for the marker
      const markerEl = document.createElement("div");
      
      // Render MapCamera into the marker element
      ReactDOM.render(<MapCamera 
        cameraLocation={cameraLocation}
        navigate={navigate}
      />,
      markerEl);

      // Add the marker to the map
      new mapboxgl.Marker(markerEl)
        .setLngLat([cameraLocation.longitude, cameraLocation.latitude])
        .addTo(mapRef.current!);
    });

    // Cleanup function to prevent memory leaks
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MapContainer ref={mapContainerRef} id="map" />;
};
