import styled from "styled-components";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media (max-width: 768px) {
    overflow: scroll;
  }
`;

/** 
 * The main container: 
 * - Takes up 100% viewport height
 * - Prevents scrolling with overflow hidden
 * - Stacks rows vertically 
 */
const GridContainer = styled.div<{ totalRows: number }>`

  display: grid;
  
  
  gap: 6px;
  border-radius: 0.5rem;
  width: 100%;
  
  @media (min-width: 768px) {
    grid-template-rows: repeat(${props => props.totalRows}, 1fr);
    overflow: hidden;
    height: 100%; // TODO: This is not actually filling 100% of the remaining space, but it does divide the children well
  }

`;

/** 
 * A single row:
 * - Height is a fraction of 100vh based on total number of rows
 * - Columns = number of items in this row
 */
const GridRow = styled.div<{ totalRows: number; itemsInRow: number }>`

  display: grid;

  
  @media (min-width: 768px) {
    overflow: hidden;
    grid-template-columns: repeat(${props => props.itemsInRow}, 1fr);
  }

  /* Optional gap between items */
  gap: 6px;
`;

/**
 * The main content container:
 * - Takes up 100% viewport height
 * - Stacks rows vertically 
 */
const ContentContainer = styled.div<{ isMap: boolean }>`
  height: auto;

  ${props => props.isMap && `
    height: 100%;
  `}

  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  
  @media (min-width: 768px) {
    height: calc(100vh - 90px);
    flex-direction: row;
  }
  `;
  
/**
 * The sidebar container:
 * - Displays a column of items
 */
const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  position: relative;
  margin-bottom: 1rem; 
  margin-top: 1rem; 

  @media (min-width: 768px) {
    margin-top: 0; 
    margin-right: 1rem;
    width: auto;
  }
`;

const SidebarItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  cursor: pointer;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    flex-direction: column;

    p {
      display: none;
    }
  }
`;

/**
 * Splits an array into multiple sub-arrays of at most `size` items.
 * Example: chunkArray([1,2,3,4,5], 3) => [[1,2,3],[4,5]]
 */
function chunkArray<T>(arr: T[], size: number): T[][] {
  const chunks: T[][] = [];

  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }

  return chunks;
}

const getColumnCount = (count: number) => {
  if (count <= 2) return 1; // 1 or 2 images => 1 column

  if (count <= 4) return 2; // 3 or 4 images => 2 columns

  if (count <= 9) return 3; // 5 to 9 images => 3 columns

  return 4; // 10 to 12 images => 4 columns (and up if needed)
};

export default {
  PageContainer,
  ContentContainer,
  Sidebar,
  GridContainer,
  GridRow,
  SidebarItem,
  utils: {
    chunkArray,
    getColumnCount
  }
};
