import * as Redux from "redux";
import * as AT from "./users.constants";
import { IUserStore } from "../../types/store/users";
import { UserActions } from "./users.actions";
import _ from "lodash";

const initialState: IUserStore = {
  adminSettingPassord: false,
  creatingUser: false,
  currentUser: null,
  deletingUser: false,
  gettingUser: false,
  dashboardCameras: [],
  gettingUsers: false,
  isLoadingUserCameras: false,
  isUpdatingUserCameras: false,
  updatingUser: false,
  user: null,
  userCameras: [],
  users: []
};

export const users: Redux.Reducer<IUserStore> = (state: IUserStore = initialState,
  action: UserActions): IUserStore => {
  switch (action.type) {
    case AT.SET_USER:
      return { ...state };
    // GET USERS
    case AT.GET_USER:
      return {
        ...state,
        gettingUser: true,
        currentUser: null 
      };
    case AT.GET_USER_SUCCESS:
      return {
        ...state,
        gettingUser: false,
        currentUser: action.user
      };
    case AT.GET_USER_FAILURE:
      return {
        ...state,
        gettingUser: false 
      };

    case AT.UPDATE_DASHBOARD_CAMERAS: {
      const dashboardCameras = state.dashboardCameras.map(camera => {
        if (camera.camera_location_id === action.camera.camera_location_id) {
          return {
            ...camera,
            show: action.camera.show 
          };
        }

        return camera;
      });

      return {
        ...state,
        dashboardCameras
      };
    }

    // GET USERS
    case AT.GET_USERS:
      return {
        ...state,
        gettingUsers: true,
        users: [] 
      };
    case AT.GET_USERS_SUCCESS:
      return {
        ...state,
        gettingUsers: false,
        users: action.users 
      };
    case AT.GET_USERS_FAILURE:
      return {
        ...state,
        gettingUsers: false 
      };

    // CREATE USER
    case AT.CREATE_USER:
      return {
        ...state,
        creatingUser: true 
      };
    case AT.CREATE_USER_SUCCESS:
      return {
        ...state,
        creatingUser: false 
      };
    case AT.CREATE_USER_FAILURE:
      return {
        ...state,
        creatingUser: false 
      };

    // UPDATE USER
    case AT.UPDATE_USER:
      return {
        ...state,
        updatingUser: true 
      };
    case AT.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updatingUser: false 
      };
    case AT.UPDATE_USER_FAILURE:
      return {
        ...state,
        updatingUser: false 
      };

    // DELETE USER
    case AT.DELETE_USER:
      return {
        ...state,
        deletingUser: true 
      };
    case AT.DELETE_USER_SUCCESS:
      return {
        ...state,
        deletingUser: false 
      };
    case AT.DELETE_USER_FAILURE:
      return {
        ...state,
        deletingUser: false 
      };
    // ADMIN RESET USER PASSWORD
    case AT.ADMIN_RESET_PASSWORD:
      return {
        ...state,
        adminSettingPassord: true 
      };
    case AT.ADMIN_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        adminSettingPassord: false 
      };
    case AT.ADMIN_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        adminSettingPassord: false 
      };

    // ADMIN UPDATE USER ACCESS
    case AT.ADMIN_UPDATE_USER_ACCESS:
      return {
        ...state,
        isUpdatingUserCameras: true 
      };
    case AT.ADMIN_UPDATE_USER_ACCESS_SUCCESS:
      return {
        ...state,
        isUpdatingUserCameras: false 
      };
    case AT.ADMIN_UPDATE_USER_ACCESS_FAILURE:
      return {
        ...state,
        isUpdatingUserCameras: false 
      };

    // USER CAMERAS
    case AT.GET_USER_CAMERAS:
      return {
        ...state,
        isLoadingUserCameras: true 
      };
    case AT.GET_USER_CAMERAS_SUCCESS: {
      const userCameraIds = action.userCameras.map(c => c.id);
      const dashboardCameraIds = state.dashboardCameras?.map(c => c.camera_location_id) || [];
      const shouldUpdateDashboardCameras = _.difference(userCameraIds, dashboardCameraIds).length > 0;
      
      return {
        ...state,
        isLoadingUserCameras: false,
        userCameras: action.userCameras,
        // If the user has new cameras, update the dashboard cameras. All cameras should be in the list but only 12 should be shown.  
        dashboardCameras: shouldUpdateDashboardCameras ? action.userCameras?.map((camera, index) => ({
          camera_location_id: camera.id,
          show: index < 12
        })) : state.dashboardCameras
      };
    }
    case AT.GET_USER_CAMERAS_FAILURE:
      return {
        ...state,
        isLoadingUserCameras: false 
      };

    case AT.PURGE_USERS:
      return initialState;
    default:
      return state;
  }
};
