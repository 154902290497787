import { Column, Row } from "hedron";
import _ from "lodash";
import * as moment from "moment";
import * as React from "react";
import GoogleAnalytics from "react-ga";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as Redux from "redux";
import { ThunkDispatch } from "redux-thunk";
import { CombinedCamerasDropdown } from "src/components/camera-dropdown";
import TimeIndicator from "src/components/time-indicator";
import logger from "src/lib/logger";
import { signOutRequest } from "src/store/auth/auth.actions";
import { getSitesRequest } from "src/store/sites/sites.actions";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { ICustomer } from "src/store/customers/customers.api";
import { IImage, IImageStore } from "src/types/store/images";
import { ISite } from "src/store/sites/sites.api";
import { IStore } from "src/types/store/store";
import { IUser, IUserCameraAssociations } from "src/types/store/users";
import { IVideo, IVideoParams } from "src/types/store/videos";
import { Auth } from "aws-amplify";

import {
  DateTimeIndicator,
  DesktopFooterLayout,
  Footer,
  Header,
  ImageArrows,
  ImageControls,
  ImagePlayer,
  ImageScrubber,
  ImageViewPanel,
  LatestButton,
  Loading,
  Logo,
  Menu,
  MobileFooterLayout,
  Navigation,
  RenderDatePicker,
  SharingActions,
  SuspendedView,
  Text
} from "../../components";
import { getInterval } from "../../lib/date-helpers";
import { styles } from "../../lib/styles";
import { getCameraLocationsRequest } from "../../store/camera-locations/camera-locations.actions";
import { cameraLocationGetByUUID } from "../../store/camera-locations/camera-locations.getters";
import { getCustomerRequest } from "../../store/customers/customers.actions";
import {
  checkNewImageRequest,
  getAvailableDatesRequest,
  getImagesByDateRequest,
  purgeImages,
  updateImageRequest
} from "../../store/images/images.actions";
import { siteGetById } from "../../store/sites/sites.getters";
import { getUserCamerasRequest } from "../../store/users/users.actions";
import { getVideosRequest } from "../../store/videos/videos.actions";
import { IAuthStore } from "../../types/store/auth";
import { BottomLeftOverlay } from "src/components/image-overlays/BottomLeftOverlay";
import { BottomRightOverlay } from "src/components/image-overlays/BottomRightOverlay";

interface IImageViewerRouterProps {
  customerId: string;
  siteId: string;
  cameraLocationUUID: string;
}

type IImageViewerProps = RouteComponentProps<IImageViewerRouterProps>;

interface IDispatchProps {
  getUserCameras: (userId: number) => void;
  checkNewImage: (jobRef: string) => void;
  getAvailableDates: (cameraLocationId: string) => void;
  getCustomer: (customerId: string) => void;
  getCameraLocations: (siteId: string, status: string | undefined, redirect: boolean) => void;
  getSites: (customerId: string) => void;
  getImages: (
    startDate: string,
    endDate: string,
    jobRef: string,
    status: string
  ) => void;
  getVideos: (cameraLocationId: string, params: IVideoParams) => void;
  purgeImageStore: () => void;
  updateImage: (
    imageIndex: number,
    id: number | undefined,
    data: IImage
  ) => void;
  signOut: () => void;
}

interface IStateProps {
  user: IUser | null;
  auth: IAuthStore;
  customer: ICustomer | null;
  site: ISite | null;
  sites: ISite[];
  videos: IVideo[];
  imageStore: IImageStore;
  loadingCustomer: boolean;
  loadingLocations: boolean;
  cameraLocations: ICameraLocation[];
  cameraLocation: ICameraLocation | null;
  userCameras: IUserCameraAssociations[];
}

interface IState {
  transform: {
    left: number;
    top: number;
    scale: number | string;
    posTop: number;
    posLeft: number;
  };
  activeStartDate: moment.Moment;
  activeEndDate: moment.Moment;
  active: number | null;
  hovered: number | null;
  dragged: number | null;
  compareMode: "vertical" | "horizontal" | "overlay" | "difference";
  zoomed: boolean;
  latestMode: boolean;
  overlaysAlwaysOn: boolean;
  isCalendarOpen: boolean;
  isChangingCamera: boolean;
  isChangingDate: boolean;
  isCompareActive: boolean;
  isOverlayActive: boolean;
  isPlaying: boolean;
  allowKeys: boolean;
  images: IImage[];
}

const initialState: IState = {
  active: null,
  activeEndDate: moment()
    .endOf("day")
    .utc(),
  activeStartDate: moment()
    .startOf("day")
    .utc(),
  compareMode: "vertical",
  dragged: null,
  hovered: null,
  images: [],
  isCalendarOpen: false,
  isChangingCamera: false,
  isChangingDate: false,
  isCompareActive: false,
  overlaysAlwaysOn: false,
  isOverlayActive: true,
  isPlaying: false,
  latestMode: true,
  allowKeys: true,
  transform: {
    left: 0,
    top: 0,
    scale: "auto",
    posTop: 0,
    posLeft: 0
  },
  zoomed: false
};

export type ImageViewerProps = IImageViewerProps & IStateProps & IDispatchProps;

class ImageViewer extends React.Component<ImageViewerProps> {
  public readonly state: IState = initialState;
  public imageRef: any;
  public timer: number;
  private boundOnKeydown: () => void;
  private wrapper = React.createRef<HTMLDivElement>();

  constructor(props: ImageViewerProps) {
    super(props);
    this.boundOnKeydown = this.handleKeyEvent.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  public UNSAFE_componentWillMount() {
    this.stopLatestModeTimer();

    document.addEventListener(
      "keydown", this.boundOnKeydown, { passive: true }
    );
  }

  public componentWillUnmount() {
    this.stopLatestModeTimer();
    document.removeEventListener("keydown", this.boundOnKeydown);
  }

  public async componentDidMount() {
    const {
      getAvailableDates,
      getCameraLocations,
      getCustomer,
      getSites,
      customer,
      auth,
      getUserCameras,
      user
    } = this.props;

    const {
      cameraLocationUUID, siteId, customerId
    } = this.props.match.params;

    const isPublic = this.props.location.pathname.includes("public");

    // Store this as the new most recently viewed camera location
    const lastCameraData = {
      customerId,
      siteId,
      cameraLocationUUID
    };

    window.localStorage.setItem("@interval-films-last-camera", JSON.stringify(lastCameraData));
 
    try {
      // Attempt to redirect a user to the customer url if logged in
      const currentSession = await Auth.currentSession();
 
      if (isPublic && user && currentSession.isValid()) {
        const newPath = this.props.location.pathname.replace("public", "customer");

        this.props.history.push(newPath);
      }
    } catch (error) {
      // No current user, keep them on the public page
    }

    try {
      if (!customer || !customer.id || customer.id.toString() !== customerId) {
        getCustomer(customerId);
      }

      if (!auth.authenticated) {
        // If admin, or if they aren't logged in at all,
        // we need to get sites rather than use userCameras
        getSites(customerId);
      }

      if (user) {
        getUserCameras(user.id);
      }

      if (cameraLocationUUID === "find") {
        getCameraLocations(
          siteId, "active", true
        );

        return;
      } else {
        getCameraLocations(
          siteId, undefined, false
        );
      }

      getAvailableDates(cameraLocationUUID);
      this.handleSetActive(0);

      GoogleAnalytics.event({
        action: "Customer",
        category: "Navigation",
        label: this.props.customer ? this.props.customer.name : "Unknown"
      });

      GoogleAnalytics.event({
        action: "Site",
        category: "Navigation",
        label: `${
          this.props.customer ? this.props.customer.name : "Unknown"
        } - ${this.props.site ? this.props.site.name : "Unknown"}`
      });

      GoogleAnalytics.event({
        action: "Camera location",
        category: "Navigation",
        label: `${
          this.props.customer ? this.props.customer.name : "Unknown"
        } - ${this.props.site ? this.props.site.name : "Unknown"} - ${
          this.props.cameraLocation ? this.props.cameraLocation.name : "Unknown"
        }`
      });
    } catch (error) {
      logger.error(error);
    }

    setTimeout(() => {
      this.setState({ isOverlayActive: false });
    }, 3000);
  }

  public async componentDidUpdate(prevProps: Readonly<ImageViewerProps>): Promise<void> {
    // Trigger image fetch when the dates load in
    if (prevProps.imageStore.dates.length !== this.props.imageStore.dates.length) {
      this.stopLatestModeTimer();
      this.getAndJumpToLatest();
      this.startLatestModeTimer();
    }

    // Trigger image & video fetch when the current camera location loads in
    if (prevProps.cameraLocation !== this.props.cameraLocation) {
      // Fetch videos if there's none in state or they aren't for the current camera location
      if (this.props.cameraLocation && (!this.props.videos.length || this.props.videos[0].camera_location_id !== String(this.props.cameraLocation.id))) {
        this.props.getVideos(String(this.props.cameraLocation.id), {});
      }

      this.stopLatestModeTimer();
      this.getAndJumpToLatest();
      this.startLatestModeTimer();
    }
  }

  public async UNSAFE_componentWillReceiveProps(newProps: ImageViewerProps) {
    const {
      auth,
      getAvailableDates,
      getCameraLocations,
      getCustomer,
      getSites,
      getVideos,
      userCameras,
      getUserCameras,
      user
    } = this.props;

    const {
      cameraLocationUUID, customerId, siteId
    } = this.props.match.params;

    const newParams = newProps.match.params;

    // handle change is site
    // handles change camera
    if (siteId !== newParams.siteId && newParams.cameraLocationUUID === "find") {
      this.stopLatestModeTimer();

      // handle change in camera
      await getCameraLocations(
        newParams.siteId, "active", true
      );

      GoogleAnalytics.event({
        action: "Site",
        category: "Navigation",
        label: `${
          this.props.customer ? this.props.customer.name : "Unknown"
        } - ${this.props.site ? this.props.site.name : "Unknown"}`
      });

      return;
    } else if (siteId !== newParams.siteId) {
      this.stopLatestModeTimer();

      await getCameraLocations(
        newParams.siteId, "active", false
      );

      GoogleAnalytics.event({
        action: "Site",
        category: "Navigation",
        label: `${
          this.props.customer ? this.props.customer.name : "Unknown"
        } - ${this.props.site ? this.props.site.name : "Unknown"}`
      });
    } else if (
      cameraLocationUUID !== "find" &&
      newParams.cameraLocationUUID === "find"
    ) {
      this.stopLatestModeTimer();

      this.props.history.push(`${window.location.pathname.replace("find",
        this.props.cameraLocations[0].id.toString())}`);

      return;
    }

    if (customerId !== newParams.customerId) {
      this.stopLatestModeTimer();
      await getCustomer(newParams.customerId);

      if (!auth.authenticated) {
        // If admin, or if they aren't logged in at all,
        // we need to get sites rather than use userCameras
        await getSites(customerId);
      }

      if ((!userCameras || userCameras.length === 0) && user) {
        getUserCameras(user.id);
      }

      GoogleAnalytics.event({
        action: "Customer",
        category: "Navigation",
        label: this.props.customer ? this.props.customer.name : "Unknown"
      });
    }

    // handle change in camera
    if (cameraLocationUUID !== newParams.cameraLocationUUID) {
      this.stopLatestModeTimer();

      try {
        await getAvailableDates(newParams.cameraLocationUUID);
        await getVideos(String(this.props.cameraLocation?.id), {});
        this.handleSetActive(0);

        this.setState({
          transform: {
            left: 0,
            top: 0,
            scale: "auto"
          }
        });
        this.stopLatestModeTimer();
        this.getAndJumpToLatest();
        this.startLatestModeTimer();

        GoogleAnalytics.event({
          action: "Camera location",
          category: "Navigation",
          label: `${
            this.props.customer ? this.props.customer.name : "Unknown"
          } - ${this.props.site ? this.props.site.name : "Unknown"} - ${
            this.props.cameraLocation
              ? this.props.cameraLocation.name
              : "Unknown"
          }`
        });
      } catch (error) {
        logger.error(error);
      }
    }
  }

  public render() {
    const {
      customer, videos, match
    } = this.props;

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    let minusHeight = 0;
    const footer = document.getElementById("footer");
    const header = document.getElementById("header");

    if (footer && header) {
      minusHeight = footer.clientHeight + header.clientHeight;
    }

    return (
      <div ref={this.wrapper} key={this.props.match.params.cameraLocationUUID}>
        <Header>
          <Row alignItems="center" style={{ width: "100vw" }}>
            <Logo logo={customer && customer.logo ? customer.logo : ""} />
            <Column xs={7} md={5} lg={4} >
              <CombinedCamerasDropdown
                customer={customer}
                sites={this.props.sites}
                handleCameraChange={data => this.handleCameraChange(data)}
                userCameras={this.props.userCameras}
                cameraLocations={this.props.cameraLocations}
                cameraLocation={this.props.cameraLocation}
                auth={this.props.auth}
                showCameraSwitcherButtons
              />
            </Column>
            <Navigation
              customerId={match.params.customerId}
              siteId={match.params.siteId}
              ip_camera_url={this.props.cameraLocation?.ip_camera_url}
              hasVideos={videos.length > 0}
              cameraLocationUUID={match.params.cameraLocationUUID}
              public_url={match.path.includes("/public/") ? true : false}
            />
            <Menu 
              navigate={this.props.history.push} 
              route={this.props.match.path} 
              match={this.props.match}
              location={this.props.location}
              history={this.props.history}
            />
          </Row>
        </Header>
        {this.renderLoading()}
        <div
          style={{
            height: windowHeight - minusHeight,
            position: "relative",
            width: windowWidth
          }}
        >
          {this.renderImageViewer()}
          {this.state.isPlaying && this.renderImagePlayer()}
        </div>

        <Footer>
          {window.matchMedia("(max-width: 800px)").matches ? (
            <MobileFooterLayout
              top={this.renderImageScrubber()}
              topLeft={this.renderDatePicker()}
              topCenter={this.renderTimeIndicator()}
              topRight={this.renderLatestModeButton()}
              bottomLeft={null}
              bottomCenter={this.renderImageControls()}
              bottomRight={null}
            />
          ) : (
            <DesktopFooterLayout
              left={this.renderSharingActions()}
              midLeft={this.renderDatePicker()}
              midCenter={this.renderImageScrubber()}
              midRight={this.renderImageControls()}
              right={this.renderLatestModeButton()}
            />
          )}
        </Footer>
      </div>
    );
  }

  private handleSetActive(active: number | null,
    stopLatestModeTimer?: boolean) {
    this.setState({
      active,
      zoomed: false
    });

    if (stopLatestModeTimer) {
      this.stopLatestModeTimer();
    }
  }

  private handleSetDragged(dragged: number | null) {
    this.setState({ dragged });
    this.stopLatestModeTimer();
  }

  private handleSetHovered(hovered: number | null) {
    this.setState({ hovered });
  }

  private handleHover(hovered: number | null) {
    if (this.state.dragged === null) {
      this.handleSetHovered(hovered);
    }
  }

  private renderImageScrubber() {
    const {
      dragged, active, hovered
    } = this.state;

    const { imageStore } = this.props;

    return (
      <div style={{ position: "relative" }}>
        {imageStore.gettingImages && <Loading />}
        <ImageScrubber
          images={imageStore.images}
          handleMouseMove={() => this.handleMouseMove()}
          dragged={dragged}
          hovered={hovered}
          active={active}
          setActive={a => this.handleSetActive(a, true)}
          setHovered={h => this.handleHover(h)}
          setDragged={d => this.handleSetDragged(d)}
          isPlaying={this.state.isPlaying}
          renderDateTimeIndicator={
            hovered === null && (
              <DateTimeIndicator
                dateTime={this.getActiveImageDateTime()}
                top={-25}
                activeDraggedImage={
                  dragged !== null ? imageStore.images[dragged].taken_at : null
                }
                hidesOnDesktopDrag={true}
              />
            )
          }
        />
      </div>
    );
  }

  private renderTimeIndicator() {
    return <TimeIndicator time={this.getActiveImageDateTime()} />;
  }

  private renderLatestModeButton() {
    const { imageStore, cameraLocation } = this.props;
    const { latestMode } = this.state;
    const cameraIsDelayed = cameraLocation && cameraLocation.delay;
    const isMobile = window.matchMedia("(max-width: 800px)").matches;

    return (
      <div style={{
        display: "flex",
        flexDirection: isMobile ? "column-reverse" : "row",
        gap: "1rem",
        alignItems: "center",
        position: isMobile ? "absolute" : "static",
        bottom: isMobile ? "2.5rem" : "auto"
      }}>
        <LatestButton
          onActivateLatestMode={() => this.handleToggleLatestMode()}
          onChangeDate={(startDate, endDate) =>
            this.handleDateChange(startDate, endDate)
          }
          dates={imageStore.dates}
          isLatestOn={latestMode}
          isDelayed={cameraIsDelayed ? true : false}
        />
      </div>
    );
  }

  private renderImageControls() {
    const { isPlaying, active } = this.state;
    const { imageStore } = this.props;

    if (imageStore.images.length) {
      return (
        <ImageControls
          playIcon={
            isPlaying
              ? "pause"
              : active && active < imageStore.images.length - 1
                ? "play"
                : "play-from-start"
          }
          dates={imageStore.dates}
          startDate={this.state.activeStartDate}
          endDate={this.state.activeEndDate}
          handleNext={() => this.handleNextInterval()}
          handlePrev={() => this.handlePrevInterval()}
          handlePlayPause={() => this.handlePlayPause()}
        />
      );
    }

    return <div />;
  }

  private renderDatePicker = () => {
    const { activeStartDate, activeEndDate } = this.state;
    const { imageStore } = this.props;

    return (
      <RenderDatePicker
        activeEndDate={activeEndDate}
        activeStartDate={activeStartDate}
        handleDateChange={this.handleDateChange}
        imageStore={imageStore}
      />
    );
  };

  private renderSharingActions() {
    const {
      auth, user, imageStore 
    } = this.props;

    const { active } = this.state;
  
    if (imageStore.images.length && user) {
      if (active !== null) {
        return (
          <SharingActions
            auth={auth}
            isAlwaysOn={this.state.overlaysAlwaysOn}
            image={imageStore.images[active]}
            toggleHandleAlwaysOn={() => this.setState({ overlaysAlwaysOn: !this.state.overlaysAlwaysOn })}
            handleSuspendImage={() => this.handleSuspendImage()}
            handleHiddenPlayImage={() => this.handleHiddenPlayImage()}
            user={user}
            fullSecurity={this.props.cameraLocation?.site?.security_level === 1 || this.props.cameraLocation?.site?.customer?.security_level === 1}
          />
        );
      }

      return null;
    }

    return null;
  }

  private handlePlayPause() {
    const { isPlaying, active } = this.state;
    const { images } = this.props.imageStore;

    this.stopLatestModeTimer();

    if (isPlaying) {
      this.setState({ isPlaying: false });

      return;
    }

    if (active !== null && active >= images.length - 1) {
      this.handleSetActive(0);
    }

    this.setState({
      isPlaying: true,
      zoomed: false
    });
  }

  private renderImagePlayer() {
    return (
      <ImagePlayer
        setImageAttribute={imageIndex => this.setImageLoaded(imageIndex)}
        isPlaying={this.state.isPlaying}
        setActive={a => this.handleSetActive(a)}
        images={this.props.imageStore.images}
        active={this.state.active}
        playerDidFinish={() => this.setState({ isPlaying: false })}
      />
    );
  }

  private setImageLoaded(imageIndex?: number) {
    const { images } = this.props.imageStore;

    if (imageIndex !== undefined) {
      images[imageIndex].loaded = true;
    }
  }

  private renderImageViewer(): React.ReactChild {
    const {
      customer, site, cameraLocation, imageStore
    } = this.props;

    const {
      zoomed, dragged, active, isPlaying
    } = this.state;

    let suspended = false;

    if (cameraLocation && cameraLocation.status === "suspended") {
      suspended = true;
    } else if (site && site.status === "suspended") {
      suspended = true;
    } else if (customer && customer.status === "suspended") {
      suspended = true;
    }

    if (suspended && !this.props.auth.admin) {
      return <SuspendedView />;
    } else if (imageStore.images.length > 0) {
      return (
        <React.Fragment>
          <Row style={{ position: "relative" }}>
            <ImageViewPanel
              wait={500}
              zoomed={zoomed}
              activeEndDate={this.state.activeEndDate}
              activeStartDate={this.state.activeStartDate}
              cameraLocation={cameraLocation}
              isPlaying={isPlaying}
              handleZoom={(didZoom: boolean) => this.handleZoom(didZoom)}
              dragged={dragged !== null ? imageStore.images[dragged] : null}
              image={this.getActiveImage()}
              isOverlayActive={this.state.isOverlayActive}
              isCompareActive={this.state.isCompareActive}
              handleMouseMove={() => this.handleMouseMove()}
              handleToggleCompare={() => this.handleToggleCompare()}
              compareMode={this.state.compareMode}
              allowKeys={allow => this.handleAllowKeys(allow)}
              transform={{
                left: this.state.transform.left,
                posLeft: this.state.transform.posLeft,
                posTop: this.state.transform.posTop,
                scale: this.state.transform.scale,
                top: this.state.transform.top
              }}
            />
            {active !== null && (
              <>
                <ImageArrows
                  isOverlayActive={this.state.isOverlayActive}
                  total={imageStore.images.length}
                  current={active}
                  startDate={this.state.activeStartDate}
                  endDate={this.state.activeEndDate}
                  dates={this.props.imageStore.dates}
                  handleNext={() => this.handleNextImage()}
                  handlePrev={() => this.handlePrevImage()}
                />
                <BottomLeftOverlay 
                  isAdmin={this.props.auth.admin}
                  alwaysOn={this.state.overlaysAlwaysOn}
                  isOverlayActive={this.state.isOverlayActive}
                  activeImage={this.getActiveImage()}
                  cameraLocation={cameraLocation}
                  hidesOnDesktopDrag={true}
                />
                <BottomRightOverlay 
                  isOverlayActive={this.state.isOverlayActive}
                  activeImage={this.getActiveImage()}
                  isAdmin={this.props.auth.admin}
                  cameraLocation={cameraLocation}
                  hidesOnDesktopDrag={true}
                  alwaysOn={this.state.overlaysAlwaysOn}
                />
              </>
            )}
          </Row>
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  }

  private handleAllowKeys(allowKeys: boolean) {
    this.setState({ allowKeys });
  }

  private handleToggleCompare() {
    const { match, history } = this.props;
    let url = "customer";

    if (match.path.includes("/public/")) {
      url = "public";
    }

    history.push(`/${url}/${match.params.customerId}/site/${match.params.siteId}/camera/${match.params.cameraLocationUUID}/compare`);
  }
  private handleMouseMove() {
    if (!this.state.isOverlayActive) {
      this.setState({ isOverlayActive: true });

      setTimeout(() => {
        this.setState({ isOverlayActive: false });

        return;
      }, 3000);
    }
  }

  private getClosestImagetoTimestamp(
    images: IImage[],
    time: moment.Moment,
    latest?: boolean
  ) {
    let target = moment(time).utc();

    if (latest) {
      return images.length - 1;
    }

    if (images.length) {
      const firstImage = moment(images[0].taken_at).utc();
      let diff = firstImage.startOf("day").diff(target, "days");
      const adjust = firstImage.isAfter(target) ? 1 : 0;

      if (latest) {
        diff = 0;
      }

      diff = diff + adjust;
      target = target.add(diff, "days").utc();

      const sorted: IImage[] = _.filter(images,
        image => image.taken_at !== null);

      sorted.sort((a, b) => {
        const timeA = moment(a.taken_at).utc();
        const timeB = moment(b.taken_at).utc();
        const dA = Math.abs(timeA.valueOf() - target.valueOf());
        const dB = Math.abs(timeB.valueOf() - target.valueOf());

        if (dA < dB) {
          return -1;
        } else if (dA > dB) {
          return 1;
        } else {
          return 0;
        }
      });

      const indexToReturn = images
        .map(item => item.taken_at)
        .indexOf(sorted[0].taken_at);

      return indexToReturn;
    }

    return 0;
  }

  private handleKeyEvent(e: KeyboardEvent) {
    const keyCode = e.keyCode;

    if (!this.state.allowKeys) {
      return;
    }

    this.setState({ isOverlayActive: false });

    if (e.metaKey || e.shiftKey || e.altKey || e.ctrlKey) {
      return;
    }

    if (this.state.isPlaying) {
      switch (keyCode) {
        case 32:
          this.handlePlayPause();
          break;
      }

      return;
    }

    switch (keyCode) {
      case 39:
        this.handleNextImage();
        break;
      case 37:
        this.handlePrevImage();
        break;
      case 188:
        this.handlePrevInterval();
        break;
      case 190:
        this.handleNextInterval();
        break;
      case 88:
        this.handleSuspendImage();
        break;
      case 90:
        this.handleHiddenPlayImage();
        break;
      case 32:
        this.handlePlayPause();
        break;
      default:
    }
  }

  private getActiveImageDateTime(): moment.Moment | null {
    const currentImage = this.getActiveImage();

    if (currentImage) {
      return moment(currentImage.taken_at).utc();
    }

    return null;
  }

  private handleNextImage() {
    if (this.wrapper) {
      const { active } = this.state;
      const { images } = this.props.imageStore;

      this.stopLatestModeTimer();

      if (images) {
        if (active !== null && active < images.length - 1) {
          this.setState({ latestMode: false });
          this.handleSetActive(active + 1);
        } else {
          // set to first from that day

          this.handleNextInterval(true);
        }
      }
    }
  }

  private handlePrevImage() {
    if (this.wrapper) {
      const { active } = this.state;

      this.stopLatestModeTimer();

      if (active !== null && active > 0) {
        this.setState({ latestMode: false });
        this.handleSetActive(active - 1);
      } else {
        // set to last from that day

        this.handlePrevInterval();
      }
    }
  }

  private handlePrevInterval(forceFirst = false, targetDate?: moment.Moment) {
    const { dates } = this.props.imageStore;
    const { activeStartDate, activeEndDate } = this.state;
    const interval = getInterval(activeStartDate, activeEndDate);
    const dateIndex = _.findIndex(dates, d => moment(d, "YYYY-MM-DD").isSame(activeStartDate.format("YYYY-MM-DD"), "day"));
    const targetStartDate = targetDate || activeStartDate.clone().subtract(interval, "days");
    const nextImageDate = moment.utc(new Date(dates[dateIndex + 1])).startOf("day"); // start of day for start of interval
    const endDate = nextImageDate.clone().add(interval, "days").subtract(1, "minute"); // sub a minute to take to end of interval

    if (dateIndex >= 0 && dateIndex !== dates.length - 1) {
      if (nextImageDate.isSameOrBefore(targetStartDate, "day")) {
        this.handleDateChange(
          nextImageDate, endDate, forceFirst
        );
      } else {
        this.setState({
          activeEndDate: endDate.clone(),
          activeStartDate: nextImageDate.clone()
        },
        () => {
          this.handlePrevInterval(forceFirst, targetStartDate);
        });
      }
    }
  }

  private handleNextInterval(forceFirst = false, targetDate?: moment.Moment) {
    const { dates } = this.props.imageStore;
    const { activeStartDate, activeEndDate } = this.state;
    const interval = getInterval(activeStartDate, activeEndDate);
    const dateIndex = _.findIndex(dates, d => moment(d, "YYYY-MM-DD").isSame(activeStartDate.format("YYYY-MM-DD"), "day"));
    const targetStartDate = targetDate || activeStartDate.clone().add(interval, "days");
    const nextImageDate = moment.utc(new Date(dates[dateIndex - 1])).startOf("day"); // start of day for start of interval
    const endDate = nextImageDate.clone().add(interval, "days").subtract(1, "minute"); // sub a minute to take to end of interval

    if (dateIndex > 0) {
      if (nextImageDate.isSameOrAfter(targetStartDate, "day")) {
        this.handleDateChange(
          nextImageDate, endDate, forceFirst
        );
      } else {
        this.setState({
          activeEndDate: endDate.clone(),
          activeStartDate: nextImageDate.clone()
        },
        () => {
          this.handleNextInterval(forceFirst, targetStartDate);
        });
      }

      if (!nextImageDate.isSame(dates[0], "day")) {
        this.stopLatestModeTimer();
      }
    }
  }

  private async handleSuspendImage() {
    if (!this.props.auth.admin) {
      return;
    }
    const { updateImage } = this.props;
    const { active } = this.state;
    const currentImage = this.getActiveImage();

    if (currentImage) {
      const imageData = {
        height: currentImage.height,
        job_id: currentImage.job_id,
        processing_status: currentImage.processing_status,
        status: currentImage.status,
        taken_at: currentImage.taken_at,
        thumbnails: currentImage.thumbnails,
        width: currentImage.width
      };

      if (
        currentImage.status === "active" ||
        currentImage.status === "hidden-during-play"
      ) {
        imageData.status = "suspend";
      } else {
        imageData.status = "active";
      }

      if (active !== null) {
        await updateImage(
          active, currentImage.id, imageData
        );
      }
    }
  }

  private async handleHiddenPlayImage() {
    if (!this.props.auth.admin) {
      return;
    }
    const { updateImage } = this.props;
    const { active } = this.state;
    const currentImage = this.getActiveImage();

    if (currentImage) {
      const imageData = {
        height: currentImage.height,
        job_id: currentImage.job_id,
        processing_status: currentImage.processing_status,
        status: currentImage.status,
        taken_at: currentImage.taken_at,
        thumbnails: currentImage.thumbnails,
        width: currentImage.width
      };

      if (currentImage.status === "active") {
        imageData.status = "hidden-during-play";
      } else {
        imageData.status = "active";
      }

      if (active !== null) {
        await updateImage(
          active, currentImage.id, imageData
        );
      }
    }
  }

  private startLatestModeTimer() {
    const { cameraLocation, checkNewImage } = this.props;

    if (cameraLocation && cameraLocation.job_ref) {
      this.setState({ latestMode: true });
      const interval = 30000;

      this.timer = setInterval(async () => {
        await checkNewImage(cameraLocation.job_ref);
        const { latestImageDate, images } = this.props.imageStore;

        if (
          images &&
          images.length &&
          latestImageDate &&
          latestImageDate !== images[images.length - 1].taken_at
        ) {
          this.getAndJumpToLatest();
        }
      }, interval);
    }
  }

  private stopLatestModeTimer() {
    this.setState({ latestMode: false });
    clearInterval(this.timer);
  }

  private getActiveImage(): IImage | null {
    const { images } = this.props.imageStore;
    const { active, dragged } = this.state;

    if (dragged !== null) {
      return images[dragged] || null;
    } else if (active !== null) {
      return images[active] || null;
    } else {
      return null;
    }
  }

  private handleZoom(didZoom: boolean) {
    if (didZoom) {
      this.setState({ zoomed: didZoom });
    }
  }

  private async handleToggleLatestMode() {
    const { latestMode } = this.state;

    if (!latestMode) {
      this.stopLatestModeTimer();
      this.getAndJumpToLatest();
      this.startLatestModeTimer();
    } else {
      this.stopLatestModeTimer();
    }
  }

  private async getAndJumpToLatest() {
    const { imageStore } = this.props;

    if (imageStore.dates.length) {
      this.setState({
        activeEndDate: imageStore.latestImageDate
          ? moment(imageStore.latestImageDate)
            .utc()
            .endOf("day")
          : moment(imageStore.dates[0])
            .utc()
            .endOf("day"),
        activeStartDate: imageStore.latestImageDate
          ? moment(imageStore.latestImageDate)
            .utc()
            .startOf("day")
          : moment(imageStore.dates[0])
            .utc()
            .startOf("day")
      },
      async () => {
        await this.getViewerImages(true);
      });
    }
  }

  private async handleDateChange(
    startDate: moment.Moment,
    endDate: moment.Moment,
    forceFirst = false
  ) {
    // Fire a Google Analytics event tracking the customer, site, camera location & user ID
    GoogleAnalytics.event({
      action: "DateRangeChange",
      category: "ImageViewer",
      label: `Customer: ${this.props.customer?.name || "Unknown"}, Site: ${this.props.site?.name || "Unknown"}, Camera Location: ${this.props.cameraLocation?.name || "Unknown"}, User: ${this.props.user?.id || "Unknown"}`
    });
    this.stopLatestModeTimer();

    if (this.state.isPlaying) {
      this.handlePlayPause();
    }

    this.setState({
      activeEndDate: endDate.clone(),
      activeStartDate: startDate.clone(),
      isCalendarOpen: false,
      isChangingDate: true
    },
    async () => {
      await this.getViewerImages(false, forceFirst);
      this.setState({ isChangingDate: false });
    });
  }

  private renderLoading() {
    if (
      this.props.imageStore.gettingDates ||
      this.props.loadingCustomer ||
      this.props.loadingLocations ||
      this.state.isChangingCamera ||
      (this.props.imageStore.images.length <= 0 && this.props.imageStore.gettingImages)
    ) {
      return (
        <Row>
          <Column
            style={{
              alignItems: "center",
              display: "flex",
              height: `calc(100vh - ${styles.header})`,
              justifyContent: "center"
            }}
          >
            <Text fontSize="h4">Loading...</Text>
          </Column>
        </Row>
      );
    } else if (this.props.imageStore.images.length <= 0) {
      return (
        <Row>
          <Column
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              height: `calc(100vh - ${styles.header})`,
              justifyContent: "center"
            }}
          >
            <Text fontSize="h4">Sorry, but there aren&apos;t any images to show you for this camera!</Text>
            <Text fontSize="h4">If this doesn&apos;t seem right please contact the office on 0117 3708519 or email <a href="mailto:support@intervalfilms.com" style={{ color: styles.primaryAccentColor }}>support@intervalfilms.com</a>.</Text>
          </Column>
        </Row>
      );
    }

    return null;
  }

  private async getViewerImages(latest = false, forceFirst = false) {
    const { cameraLocation, auth } = this.props;

    const {
      activeStartDate, activeEndDate, active
    } = this.state;

    const time = latest
      ? moment()
        .utc()
        .endOf("day")
      : this.getActiveImageDateTime();

    const start = activeStartDate
      .startOf("day")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");

    const end = activeEndDate
      .endOf("day")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");

    const status = auth.admin ? "" : "active";

    try {
      if (cameraLocation) {
        await this.props.getImages(
          start, end, cameraLocation.job_ref, status
        );
        let closestIndex = 0;

        this.setState({ isChangingCamera: false },
          () => {
            if (forceFirst) {
              closestIndex = 0;
            } else if (active === 0) {
              closestIndex = this.props.imageStore.images.length - 1;
            } else if (activeStartDate.isSame(activeEndDate, "day")) {
            // if the same day then find index
              closestIndex = this.getClosestImagetoTimestamp(this.props.imageStore.images,
                (time && time.utc()) || moment.utc());
            }

            this.handleSetActive(closestIndex);

            return;
          });
      }
    } catch (error) {
      logger.error(error);
    }
  }

  private async handleCameraChange(userCameraLocation: IUserCameraAssociations) {
    const { history, match } = this.props;

    this.setState({ isChangingCamera: true });

    if (
      this.props.cameraLocation &&
      userCameraLocation.id === this.props.cameraLocation.id
    ) {
      return;
    }

    let url = "customer";

    if (match.path.includes("/public/")) {
      url = "public";
    }

    // Store this as the new most recently viewed camera location
    const lastCameraData = {
      customerId: userCameraLocation.site.customer.id,
      siteId: userCameraLocation.site.id,
      cameraLocationUUID: userCameraLocation.uuid
    };

    window.localStorage.setItem("@interval-films-last-camera", JSON.stringify(lastCameraData));
    history.push(`/${url}/${userCameraLocation.site.customer.id}/site/${userCameraLocation.site.id}/camera/${userCameraLocation.uuid}/images`);
  }
}

const mapStateToProps = (state: IStore, props: ImageViewerProps): IStateProps => {
  const { match } = props;

  return {
    auth: state.auth,
    cameraLocation: cameraLocationGetByUUID(state, match.params.cameraLocationUUID),
    cameraLocations: state.cameraLocations.cameraLocations,
    customer: state.customers.currentCustomer,
    imageStore: state.images,
    loadingCustomer: state.customers.gettingCustomer,
    loadingLocations: state.cameraLocations.gettingCameraLocations,
    site: siteGetById(state, match.params.siteId),
    sites: state.sites.sites,
    user: state.users.currentUser,
    userCameras: state.users.userCameras,
    videos: state.videos.videos
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>,
  props: ImageViewerProps): IDispatchProps => {
  // based on the url we know if we want to run public or private actions
  let role = "client";

  if (props.match.path.includes("/public/")) {
    role = "public";
  }

  return {
    checkNewImage: (jobRef: string) =>
      dispatch(checkNewImageRequest(jobRef, role)),
    getAvailableDates: (cameraLocationUUID: string) =>
      dispatch(getAvailableDatesRequest(cameraLocationUUID, role)),
    getCameraLocations: (
      siteId: string, status: string, redirect: boolean
    ) =>
      dispatch(getCameraLocationsRequest(
        siteId, role, status, 1, redirect
      )),
    getCustomer: (customerId: string) =>
      dispatch(getCustomerRequest(customerId, role)),
    getImages: (
      startDate: string,
      endDate: string,
      jobRef: string,
      status: string
    ) =>
      dispatch(getImagesByDateRequest(
        startDate, endDate, jobRef, status, role
      )),
    getSites: (customerId: string) =>
      dispatch(getSitesRequest(
        customerId, "active", role
      )),
    getUserCameras: userId => dispatch(getUserCamerasRequest(userId, false)),
    getVideos: (cameraLocationId: string, params: IVideoParams) =>
      dispatch(getVideosRequest(
        cameraLocationId, params, role
      )),
    purgeImageStore: () => dispatch(purgeImages()),
    signOut: () => dispatch(signOutRequest(true)),
    updateImage: (
      imageIndex: number, id: number, data: IImage
    ) =>
      dispatch(updateImageRequest(
        imageIndex, id, data
      ))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewer);