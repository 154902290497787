import {
  Aperture,
  AudioLines,
  BatteryFull,
  Camera,
  CloudLightning,
  CloudMoon, 
  CloudMoonRain,
  CloudRain,
  CloudRainWind,
  CloudSnow,
  CloudSun,
  Cloudy,
  Droplet,
  Focus,
  HardDrive,
  MoonStar,
  PlugZap,
  Projector,
  Ruler,
  Sun,
  Thermometer,
  Zap
} from "lucide-react";
import React from "react";
import { WeatherIcon } from "src/types/store/images";
import { IImage } from "src/types/store/images";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";

export interface IOverlayProps {
  isOverlayActive: boolean;
  activeImage?: IImage | null;
  isAdmin: boolean;
  cameraLocation?: ICameraLocation | null;
  hidesOnDesktopDrag: boolean;
  alwaysOn: boolean;
}

export enum InfoPillLabel {
  Weather = "Weather",
  Temperature = "Temperature",
  Humidity = "Humidity",
  Voltage = "Voltage",
  DiskSpace = "Disk Space",
  Aperture = "Aperture",
  Shutter = "Shutter",
  ISO = "ISO",
  FocusMode = "Focus Mode",
  ExposureMode = "Exposure Mode",
  Dimensions = "Dimensions",
  PowerMode = "Power Mode"
}

export interface IInfoPill {
  label: InfoPillLabel;
  value: string | number | WeatherIcon;
  display_text: string;
  visibleToAdmin: boolean;
  visibleToClient: boolean;
}

export const getIconForInfoPill = (infoPill: IInfoPill) => {
  if (infoPill.label === InfoPillLabel.Weather) {
    switch (infoPill.value as WeatherIcon) {
      case "01d": // clear sky (day)
        return <Sun />;
      case "01n": // clear sky (night)
        return <MoonStar />;
      case "02d": // few clouds (day)
        return <Cloudy />;
      case "02n": // few clouds (night)
        return <CloudMoon />;
      case "03d": // scattered clouds (day)
        return <CloudSun />;
      case "03n": // scattered clouds (night)
        return <CloudMoon />;
      case "04d": // broken clouds (day)
        return <Cloudy />;
      case "04n": // broken clouds (night)
        return <Cloudy />;
      case "09d": // shower rain (day)
        return <CloudRainWind />;
      case "09n": // shower rain (day)
        return <CloudRainWind />;
      case "10d": // rain (day)
        return <CloudRain />;
      case "10n": // rain (night)
        return <CloudMoonRain />;
      case "11d": // thunderstorm (day)
        return <CloudLightning />;
      case "11n": // thunderstorm (night)
        return <CloudLightning />;
      case "13d": // snow (day)
        return <CloudSnow />;
      case "50d": // mist (day)
        return <CloudSun />;
      case "50n": // mist (night)
        return <CloudMoon />;
    }
  }

  if (infoPill.label === InfoPillLabel.Temperature) return <Thermometer />;

  if (infoPill.label === InfoPillLabel.Humidity) return <Droplet />;

  if (infoPill.label === InfoPillLabel.Voltage) return <Zap />;

  if (infoPill.label === InfoPillLabel.DiskSpace) return <HardDrive />;

  if (infoPill.label === InfoPillLabel.Aperture) return <Aperture />;

  if (infoPill.label === InfoPillLabel.Shutter) return <Projector />;

  if (infoPill.label === InfoPillLabel.ISO) return <AudioLines />;

  if (infoPill.label === InfoPillLabel.FocusMode) return <Focus />;

  if (infoPill.label === InfoPillLabel.ExposureMode) return <Camera />;

  if (infoPill.label === InfoPillLabel.Dimensions) return <Ruler />;

  if (infoPill.label === InfoPillLabel.PowerMode) {
    if (infoPill.value === "solar") return <Sun />;

    if (infoPill.value === "battery") return <BatteryFull />;

    if (infoPill.value === "mains") return <PlugZap />;
  }

  return null;
};
