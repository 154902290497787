import { ChevronUp, ChevronDown } from "lucide-react";
import React, { useState } from "react";
import { AdjustableSetting } from "src/store/camera-locations/camera-locations.api";
import { IAdjustableSetting, SettingsAction } from "./AdjustableSettingsReducer";
import Panel from "./SettingsPanelComponents";

export const AdjustableSettingDropdown: React.FC<{
  setting: IAdjustableSetting;
  type?: "dropdown" | "toggle";
  dispatch: React.Dispatch<SettingsAction<keyof AdjustableSetting>>;
}> = ({
  setting, dispatch, type = "dropdown"
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Panel.Row>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "0.5rem" 
        }}>
          {setting.icon}
          <Panel.SettingLabel>{setting.label}</Panel.SettingLabel>
        </div>
        {type === "dropdown" && (
          <Panel.DropdownTrigger onClick={() => setOpen(!isOpen)}>
            <Panel.SettingValue>{setting.value}</Panel.SettingValue>
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </Panel.DropdownTrigger>
        )}
        {type === "toggle" && (
          <Panel.ToggleTrigger 
            isOn={setting.value === 1}
            onClick={() => {
              dispatch({
                type: "UPDATE_SETTING",
                key: setting.key,
                value: setting.value === 1 ? 0 : 1
              });
            }} 
          />
        )}
      </Panel.Row>
      {type === "dropdown" && (
        <Panel.DropdownContent isOpen={isOpen}>
          {setting.options.map((option, index) => (
            <Panel.Option 
              onClick={() => {
                dispatch({
                  type: "UPDATE_SETTING",
                  key: setting.key,
                  value: option 
                });
                setOpen(false);
              }}
              selected={setting.value === option}
              key={index}
            >{option}</Panel.Option>
          ))}
        </Panel.DropdownContent>  
      )}
    </>
  );
};
