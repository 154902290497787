import React, {
  useEffect,
  useRef,
  useState
} from "react";
import { connect } from "react-redux";
import Redux from "redux";
import { ThunkDispatch } from "redux-thunk";
import { signOut } from "src/store/auth/auth.actions";
import { IStore } from "src/types/store/store";
import { Icon, Text } from "..";
import {
  LineBreak,
  Menu,
  MenuDrawerButton,
  MenuDrawerWrapper,
  MenuItem,
  MenuWrapper,
  NavigationWrapper
} from "./components";
import { styles } from "src/lib/styles";
import CameraSettingsPanel from "./schedule-and-settings/CameraSettingsPanel";
import { cameraLocationGetByUUID } from "src/store/camera-locations/camera-locations.getters";
import { RouteComponentProps } from "react-router-dom";
import SchedulePanel from "./schedule-and-settings/SchedulePanel";

export interface RouterProps {
  customerId?: string;
  siteId?: string;
  cameraLocationUUID?: string;
}

interface INavigationProps extends RouteComponentProps<RouterProps> {
  route?: string;
  navigate: (to: string) => void;
}

const Navigation: React.FC<INavigationProps & IDispatchProps & IStore> = ({
  route, auth, signOut, navigate, currentCameraLocation
}) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const currentSite = currentCameraLocation?.site;
  const currentCustomer = currentSite?.customer;
  const [isCameraSettingsOpen, setIsCameraSettingsOpen] = useState(false);
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);

  const adminMenu = [
    {
      function: () => navigate("/admin/cameras"),
      label: "All Cameras",
      isActive: false
    },
    {
      function: () => navigate(`/admin/customer/${currentCustomer?.id}`),
      label: `Manage '${currentCustomer?.name}'`,
      isActive: false
    },
    {
      function: () => navigate(`/admin/customer/${currentCustomer?.id}/site/${currentSite?.id}`),
      label: `Manage '${currentSite?.name}'`,
      isActive: false
    },
    {
      function: () => navigate(`/admin/customer/${currentCustomer?.id}/site/${currentSite?.id}/camera/${currentCameraLocation?.uuid}`),
      label: `Manage '${currentCameraLocation?.name}'`,
      isActive: false
    },
    {
      function: () => {
        setIsCameraSettingsOpen(true);
        setOpen(false);
      },
      label: "Camera Config",
      isActive: false
    },
    {
      function: () => {
        setIsScheduleOpen(true);
        setOpen(false);
      },
      label: "Camera Schedule",
      isActive: false
    }
  ];

  const customerMenu = auth.authenticated
    ? [
      {
        function: () => navigate("/customer/dashboard"),
        label: "Dashboard",
        isActive: route?.includes("dashboard")
      },
      {
        function: () => navigate("/customer/map"),
        label: "Map",
        isActive: route?.includes("map")
      },
      {
        function: () => signOut(),
        label: "Logout",
        isActive: false
      }
    ]
    : [
      {
        function: () => signOut(),
        label: "Login",
        isActive: false
      }
    ];

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div ref={wrapperRef}>
        <NavigationWrapper>
          <MenuDrawerWrapper>
            <MenuDrawerButton onClick={() => setOpen(!open)}>
              <Icon icon="menu" />
            </MenuDrawerButton>
            <MenuWrapper open={open}>
              <Menu>
                {customerMenu.map((item, index) => (
                  <React.Fragment key={index}>
         
                    <MenuItem
                      onClick={item.function}
                    >
                      <Text 
                        fontSize={"p"}
                        style={{ color: item.isActive ? styles.primaryAccentColor : styles.primaryTextColor }}
                      >
                        {item.label}
                      </Text>
                    </MenuItem>
                  </React.Fragment>
                ))}
                {auth.admin && (
                  <LineBreak />
                )}
                {auth.admin && adminMenu.map((item, index) => (
                  <React.Fragment key={index}>
                    <MenuItem
                      onClick={item.function}
                    >
                      <Text fontSize={"p"} style={{ color: item.isActive ? styles.primaryAccentColor : styles.primaryTextColor }}>
                        {item.label}
                      </Text>
                    </MenuItem>
                  </React.Fragment>
                ))}
              
                <MenuItem style={{ cursor: "default" }}>
                  <Text fontSize="p">
                    <span>
                    Powered by{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://timelapse.co.uk/"
                        style={{
                          color: "white",
                          textDecoration: "none"
                        }}
                      >
                      Interval Films
                      </a>
                    </span>
                  </Text>
                </MenuItem>
              </Menu>
            </MenuWrapper>
          </MenuDrawerWrapper>

        </NavigationWrapper>
      </div>

      <CameraSettingsPanel 
        isOpen={isCameraSettingsOpen} 
        setOpen={setIsCameraSettingsOpen} 
        cameraLocation={currentCameraLocation}
      />

      <SchedulePanel 
        isOpen={isScheduleOpen} 
        setOpen={setIsScheduleOpen} 
        cameraLocation={currentCameraLocation}
      />

    </>
  );
};

interface IDispatchProps {
  signOut: () => void;
}

const mapStateToProps = (state: IStore, props: INavigationProps) => {
  const { cameraLocationUUID } = props.match.params;

  return {
    ...state,
    currentCameraLocation: cameraLocationUUID ? cameraLocationGetByUUID(state, cameraLocationUUID) : null
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IDispatchProps => {
  return { signOut: () => dispatch(signOut()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
