import { styles } from "src/lib/styles";
import styled from "styled-components";

export const InfoPill = styled.div<{ label: string }>`
  background-color: ${styles.primaryOverlayColour};
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  position: relative;
    
  &:hover {
    &:after {
      content: "${props => props.label}";
      position: absolute;
      top: 0;
      height: 100%;
      background-color: ${styles.primaryDarkColor};;
      font-size: 0.8rem;
      padding: 0 1rem;
      border-radius: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
    }
  }

  span {
    font-size: 0.8rem;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const OverlayWrapper = styled.div<{ position: "bottom-right" | "bottom-left" }>`
  position: absolute;
  bottom: 0;
  ${props => props.position === "bottom-right" ? "right: 0;" : "left: 0;"}
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.position === "bottom-right" ? "flex-end" : "flex-start"};
  align-items: ${props => props.position === "bottom-right" ? "flex-end" : "flex-start"};
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-right: ${props => props.position === "bottom-right" ? "1rem" : "0"};
  margin-left: ${props => props.position === "bottom-left" ? "1rem" : "0"};
  transition: opacity 0.3s ease-in-out;
 
  ${InfoPill}:hover {
    &:after {
      left: ${props => props.position === "bottom-left" ? "110%" : "auto"};
      right: ${props => props.position === "bottom-right" ? "110%" : "auto"};
    }
  }
`;