import React from "react";
import * as _ from "lodash";
import styled from "styled-components";
import { styles } from "src/lib/styles";
import Icon from "../icon";

interface IProps {
	items: ICheckListMenuItem[] | null
	onClick: (value: ICheckListMenuItem) => void;
	label: string
	parent?: string
	loading: boolean;
}

export interface ICheckListMenuItem {
	value: number
	label: string
	checkType: "checked" | "unchecked"
	qtyOfCams?: number
}

const CheckListMenu: React.SFC<IProps> = props => {
  return (
    <Wrapper>
      <h2>{props.label}</h2>
      <CheckListMenuWrapper>
        {props.loading && <p>Loading...</p>}
        {!props.loading && (!props.items || !props.items.length) && (
          <p>
            {props.parent ? `Select a ${props.parent} to show ${props.label}` : `No ${props.label}`}
          </p>
        )}
        {_.map(props.items, item => {
          return (
            <CheckListItem key={item.value} onClick={() => props.onClick(item)}>
              <CheckContainer className={item.checkType}>
                <Icon icon="tick" />
              </CheckContainer>
              <p className={`${item.qtyOfCams && "hasCams"}`}>{item.label} {item.qtyOfCams ? <span className="qty">({item.qtyOfCams} cams)</span> : null}</p>
            </CheckListItem>
          );
        })}
      </CheckListMenuWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
	width: 33%;
	margin: 15px;
	h2 {
		margin-bottom: 1.2rem;
	}
`;

export const CheckListMenuWrapper = styled.div`
		background-color: ${styles.primaryDarkColor};
		border-radius: 4px;
		padding: 0.6rem ;
		height: 25rem;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			width: 11px;
		}
		& {
			scrollbar-width: thin;
			scrollbar-color: #AEBACB ${styles.primaryDarkColor};
		}
		&::-webkit-scrollbar-track {
			background: ${styles.primaryDarkColor};
		}
		&::-webkit-scrollbar-thumb {
			background-color: #AEBACB ;
			border-radius: 6px;
			border: 3px solid ${styles.primaryDarkColor};
			
		}

`;

export const CheckListItem = styled.div`
	padding: 0.8rem 0.5rem;
	display: flex;
	flex-direction: row;
	border: 1px solid transparent;
	border-radius: 0.2rem;
	cursor: pointer;

	p {
		font-size: 1.1rem;
    line-height: 1.1rem;

		&.hasCams {
			font-weight: bold;
			color: ${styles.primaryAccentColor};
		}
	}
`;

export const CheckContainer = styled.div`
	margin-right: 0.8rem;
	cursor: pointer;
	border-radius: 0.2rem;
	width: 1.2rem;
	height: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	
	svg {
		width: 0.8rem;
		height: 0.8rem;
	}

	border: 1px solid transparent;


	&.checked {
		background-color: ${styles.primaryAccentColor};
		svg {
			fill: ${styles.primaryDarkColor};
		}
	}

	&.unchecked {
		background-color: ${styles.primaryDarkColor};
		border: 1px solid #5D697B;
		svg {
			fill: #5D697B;
		}
	}

`;

export default CheckListMenu;
