import { styles } from "src/lib/styles";
import styled from "styled-components";

const BackgroundOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${props => props.isOpen ? "auto" : "none"};
  opacity: ${props => props.isOpen ? 1 : 0};

  ~ * {
    transition: opacity 0.3s ease-in-out;
    opacity: ${props => props.isOpen ? 1 : 0};
    pointer-events: ${props => props.isOpen ? "auto" : "none"};
  }
`;

const CameraSettingsPanelWrapper = styled.div`
  position: fixed;
  top: 50%;
  * {
    user-select: none;
  }
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background-color: ${styles.secondaryDarkColor};
  overflow-x: clip;
  
  @media (min-width: 768px) {
    height: auto;
    max-height: 90vh;
    border-radius: 1rem;
    width: auto;
    min-width: 400px;
  }

`;

const SummaryValueBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
`;

const CameraSettingsPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  white-space: nowrap;
  font-weight: 600;
  font-size: 1rem;

  &::before, &::after {
    content: " ";
    width: 100%;
    height: 1px;
    background-color: ${styles.fadedTextColor};
  }
`;

const SettingLabel = styled.div`
  font-size: 0.9rem;
`;

const SettingValue = styled.div`
  font-size: 0.8rem;
  font-family: "Roboto Mono";
`;

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
`;

const Row = styled.div<{ justifyContent?: string }>`
  display: flex;
  justify-content: ${props => props.justifyContent || "space-between"};
  align-items: center;
`;

const CopyPasteButton = styled(Row)`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const DropdownTrigger = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  position: relative;
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
  width: 100%;
  z-index: 1000;
  border-radius: 0.5rem;
  align-items: end;
  padding: 1rem;
  box-sizing: border-box;

  display: ${props => props.isOpen ? "flex" : "none"};
  flex-direction: column;
  background-color: ${styles.primaryDarkColor};
  margin-top: 0.5rem;
`;

const Option = styled.div<{ selected: boolean }>`
  cursor: pointer;
  width: 100%;
  text-align: right;
  transition: all 0.3s ease-in-out;
  opacity: 0.9;
  font-size: 0.9rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-family: "Roboto Mono";
  &:hover {
    padding-right: 0.5rem;
    opacity: 1;
  }
  color: ${props => props.selected ? styles.primaryAccentColor : styles.textColor};
`;

const ToggleTrigger = styled.div<{ isOn: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  position: relative;
  width: 3rem;
  height: 1.5rem;
  border-radius: 1rem;
  background-color: ${props => props.isOn ? styles.primaryAccentColor : styles.secondaryDarkColor};
  border: 1px solid ${styles.fadedTextColor};
  position: relative;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
 
  &::before {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    content: " ";
    background-color: ${styles.primaryTextColor};
    position: absolute;
    top: 50%;
    border-radius: 100%;
    height: 100%;
    border: 2px solid ${props => props.isOn ? styles.primaryAccentColor : styles.secondaryDarkColor};
    box-sizing: border-box;
    aspect-ratio: 1 / 1;
    left: ${props => props.isOn ? "50%" : "0%"};
    transform: translateY(-50%);
  }
`;

const DayOfWeek = styled.div<{ isActive: boolean, isDisabled: boolean }>`
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;

  background-color: ${props => props.isActive ? styles.primaryAccentColor : props.isDisabled ? "transparent" : styles.fadedTextColor};
  font-weight: bold;
  cursor: pointer;
  border: 1px solid ${props => props.isDisabled ? styles.fadedTextColor : "transparent"};
  color: ${props => props.isDisabled ? styles.fadedTextColor : styles.primaryDarkColor};
`;

const DaysOfWeek = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
  padding: 2rem;
`;

export default {
  DropdownTrigger,
  DropdownContent,
  SummaryValueBox,
  DayOfWeek,
  ToggleTrigger,
  Option,
  CopyPasteButton,
  SettingLabel,
  SettingValue,
  Row,
  BackgroundOverlay,
  CameraSettingsPanelWrapper,
  CameraSettingsPanelHeader,
  SettingsWrapper,
  DaysOfWeek
};