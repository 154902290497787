import React from "react";

import {
  getIconForInfoPill, IInfoPill, InfoPillLabel, 
  IOverlayProps
} from "./overlay-utils";
import { OverlayWrapper } from "./OverlayComponent";
import { InfoPill } from "./OverlayComponent";

export const BottomRightOverlay: React.FC<IOverlayProps> = ({
  isOverlayActive,
  activeImage,
  cameraLocation,
  isAdmin,
  hidesOnDesktopDrag,
  alwaysOn
}) => {
  const info: IInfoPill[] = [
    {
      label: InfoPillLabel.PowerMode,
      visibleToAdmin: isAdmin,
      visibleToClient: false,
      value: activeImage?.mains_power ? "mains" : activeImage?.solar_power ? "solar" : "battery",
      display_text: activeImage?.mains_power ? "Mains" : activeImage?.solar_power ? "Solar" : "Battery"
    },
    {
      label: InfoPillLabel.DiskSpace,
      visibleToAdmin: isAdmin,
      visibleToClient: false,
      value: activeImage?.disk_space ? `${activeImage?.disk_space}%` : "N/A",
      display_text: activeImage?.disk_space ? `${activeImage?.disk_space}%` : "N/A"
    },
    {
      label: InfoPillLabel.Voltage,
      visibleToAdmin: isAdmin,
      visibleToClient: cameraLocation?.is_voltage_display,
      value: activeImage?.voltage ? `${activeImage?.voltage} V` : "N/A",
      display_text: activeImage?.voltage ? `${activeImage?.voltage} V` : "N/A"
    },
    {
      label: InfoPillLabel.Temperature,
      visibleToAdmin: activeImage?.weather?.temp !== undefined,
      visibleToClient: activeImage?.weather?.temp !== undefined,
      value: activeImage?.weather?.temp,
      display_text: `${activeImage?.weather?.temp}°C`
    },
    {
      label: InfoPillLabel.Humidity,
      visibleToAdmin: activeImage?.weather?.humidity !== undefined,
      visibleToClient: activeImage?.weather?.humidity !== undefined,
      value: activeImage?.weather?.humidity,
      display_text: `${activeImage?.weather?.humidity}%`
    },
    {
      label: InfoPillLabel.Weather,
      visibleToAdmin: activeImage?.weather?.weather_main !== undefined,
      visibleToClient: activeImage?.weather?.weather_main !== undefined,
      value: activeImage?.weather?.weather_icon,
      display_text: activeImage?.weather?.weather_main
    }
  
  ].filter(item => isAdmin ? item.visibleToAdmin || item.visibleToClient : item.visibleToClient) as IInfoPill[];
  
  return (
    <OverlayWrapper 
      position={"bottom-right"} 
      style={{ opacity: alwaysOn ? 1 : hidesOnDesktopDrag && !isOverlayActive ? 0 : 1 }}
    >
      {info.map(item => (
        <InfoPill label={item.label} key={item.label}>
          {getIconForInfoPill(item)}
          <span>{item.display_text}</span>
        </InfoPill>
      ))}
    </OverlayWrapper>
  );
};
