import {
  Aperture, AudioLines, Contrast, Ellipsis, FileImage, Projector
} from "lucide-react";
import React, {
  useEffect,
  useReducer,
  useRef
} from "react";
import { connect } from "react-redux";
import Redux from "redux";
import { ThunkDispatch } from "redux-thunk";
import Button from "src/components/button";
import { signOut } from "src/store/auth/auth.actions";
import { updateCameraConfigRequest } from "src/store/camera-locations/camera-locations.actions";
import { AdjustableSetting, ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { IStore } from "src/types/store/store";
import { AdjustableSettingDropdown } from "./AdjustableSettingDropdown";
import { IAdjustableSetting, settingsReducer } from "./AdjustableSettingsReducer";
import Panel from "./SettingsPanelComponents";

interface IProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  cameraLocation?: ICameraLocation | null;
}

const CameraSettingsPanel: React.FC<IProps & IDispatchProps & IStore> = ({
  isOpen, setOpen, cameraLocation, updateCameraConfig
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const latestImage = cameraLocation?.latest_image;
  
  const [settings, dispatch] = useReducer(settingsReducer, {
    desired_image_format: "JPEG",
    desired_iso: 100,
    desired_aperture: 2.8,
    desired_shutter: "1/125",
    desired_exposure_comp: 0,
    desired_white_balance: "Auto",
    desired_camera_lid_open: 0,
    desired_camera_on: 0,
    desired_heater_on: 0
  });

  useEffect(() => {
    dispatch({
      type: "RESET_TO_CAMERA_LOCATION",
      cameraLocation: cameraLocation || null
    });
  }, [cameraLocation]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpen]);
  
  const readOnlySettings = [
    {
      label: "Camera Model",
      value: cameraLocation?.camera_model || "N/A"
    },
    {
      label: "Camera Serial",
      value: cameraLocation?.camera_serial || "N/A"
    },
    {
      label: "Lens Model",
      value: cameraLocation?.lens_model || "N/A"
    },
    {
      label: "Lens Serial",
      value: cameraLocation?.lens_serial || "N/A"
    },
    {
      label: "Hardware Ref",
      value: cameraLocation?.hardware_ref || "N/A"
    },
    {
      label: "Voltage",
      value: latestImage?.voltage ? `${latestImage?.voltage} V` : "N/A"
    },
    {
      label: "Disk Space",
      value: latestImage?.disk_space ? `${latestImage?.disk_space}%` : "N/A"
    },
    {
      label: "Internal Temperature",
      value: latestImage?.internal_temp ? `${latestImage?.internal_temp}°C` : "N/A"
    },
    {
      label: "Internal Humidity",
      value: latestImage?.internal_humidity ? `${latestImage?.internal_humidity}%` : "N/A"
    }
  ];

  const adjustableSettings: IAdjustableSetting[] = [
    {
      label: "Format",
      icon: <FileImage size={16}/>,
      options: [
        "Large Fine JPEG",
        "Large Normal JPEG",
        "Medium Fine JPEG",
        "Medium Normal JPEG",
        "Small Fine JPEG",
        "Small Normal JPEG",
        "Smaller JPEG",
        "Tiny JPEG",
        "RAW + Large Fine JPEG",
        "RAW"
      ],
      value: settings.desired_image_format,
      key: "desired_image_format"
    },
    {
      label: "ISO",
      icon: <AudioLines size={16}/>,
      options: [
        100,
        200,
        400,
        800,
        1600,
        3200,
        6400
      ],
      value: settings.desired_iso,
      key: "desired_iso"
    },
    {
      label: "Aperture",
      icon: <Aperture size={16}/>,
      options: [
        4,
        4.5,
        5,
        5.6,
        6.3,
        7.1,
        8,
        9,
        10,
        11,
        13,
        14,
        16,
        18,
        20,
        22
      ],
      value: settings.desired_aperture,
      key: "desired_aperture"
    },
    {
      label: "Shutter Speed",
      icon: <Projector size={16}/>,
      options: [
        "1/1000",
        "1/500",
        "1/250",
        "1/125",
        "1/60",
        "1/30",
        "1/15",
        "1/8",
        "1/4",
        "1/2",
        "1"
      ],
      value: settings.desired_shutter,
      key: "desired_shutter"
    },
    {
      label: "Exposure Comp",
      icon: <Ellipsis size={16}/>,
      options: [
        -5,
        -4.6,
        -4.3,
        -4,
        -3.6,
        -3.3,
        -3,
        -2.6,
        -2.3,
        -2,
        -1.6,
        -1.3,
        -1,
        -0.6,
        -0.3,
        0,
        0.3,
        0.6,
        1,
        1.3,
        1.6,
        2,
        2.3,
        2.6,
        3,
        3.3,
        3.6,
        4,
        4.3,
        4.6,
        5
      ],
      value: settings.desired_exposure_comp,
      key: "desired_exposure_comp"
    },
    {
      label: "White Balance",
      icon: <Contrast size={16}/>,
      options: [
        "Auto",
        "AWB White",
        "Daylight",
        "Shadow",
        "Cloudy",
        "Tungsten",
        "Fluorescent",
        "Flash",
        "Manual"
      ],
      value: settings.desired_white_balance,
      key: "desired_white_balance"
    }
  ];

  return (
    <div ref={wrapperRef}>
      <Panel.BackgroundOverlay onClick={() => setOpen(false)} isOpen={isOpen} />
      <Panel.CameraSettingsPanelWrapper>
        <Panel.SettingsWrapper>
          {readOnlySettings.map((setting, index) => (
            <Panel.Row key={index}>
              <Panel.SettingLabel>{setting.label}</Panel.SettingLabel>
              <Panel.SettingValue>{setting.value}</Panel.SettingValue>
            </Panel.Row>
          ))}
        </Panel.SettingsWrapper>
        <Panel.CameraSettingsPanelHeader>
          Hardware
        </Panel.CameraSettingsPanelHeader>
        <Panel.SettingsWrapper>
          <AdjustableSettingDropdown
            setting={{
              label: "Camera",
              icon: null,
              options: [0, 1],
              value: settings.desired_camera_on,
              key: "desired_camera_on"
            }}
            type="toggle"
            dispatch={dispatch}
          />
          <AdjustableSettingDropdown
            setting={{
              label: "Heater",
              icon: null,
              options: [0, 1],
              value: settings.desired_heater_on,
              key: "desired_heater_on"
            }}
            type="toggle"
            dispatch={dispatch}
          />
          <AdjustableSettingDropdown
            setting={{
              label: "Camera Lid",
              icon: null,
              options: [0, 1],
              value: settings.desired_camera_lid_open,
              key: "desired_camera_lid_open"
            }}
            type="toggle"
            dispatch={dispatch}
          />
        </Panel.SettingsWrapper>
        <Panel.CameraSettingsPanelHeader>
          Adjustable Settings
        </Panel.CameraSettingsPanelHeader>
        <Panel.SettingsWrapper>
          {adjustableSettings.map((setting, index) => (
            <AdjustableSettingDropdown
              key={index}
              setting={setting}
              dispatch={dispatch}
            />
          ))}
        </Panel.SettingsWrapper>
        <Panel.SettingsWrapper>
          <Panel.Row>
            <Button
              text="Cancel"
              clickHandler={() => {
                setOpen(false);
              }}
            />
            <Button
              text="Save"
              clickHandler={() => {
                updateCameraConfig(cameraLocation?.id.toString() || "", settings);
              }}
            />
          </Panel.Row>
        </Panel.SettingsWrapper>
      </Panel.CameraSettingsPanelWrapper>
    </div>
  );
};

interface IDispatchProps {
  signOut: () => void;
  updateCameraConfig: (cameraLocationId: string, adjustableSetting: AdjustableSetting) => void;
}

const mapStateToProps = (state: IStore) => state;

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IDispatchProps => {
  return { 
    signOut: () => dispatch(signOut()),
    updateCameraConfig: (cameraLocationId: string, adjustableSetting: AdjustableSetting) => dispatch(updateCameraConfigRequest(cameraLocationId, adjustableSetting))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CameraSettingsPanel);
