import * as Redux from "redux";
import { Row } from "hedron";
import React, {
  useEffect, useRef, useState 
} from "react";
import Header from "src/components/header/header";
import { Logo, Menu } from "src/components";

import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IStore } from "src/types/store/store";
import { FaEarthEurope } from "react-icons/fa6";
import { FaBorderAll } from "react-icons/fa6";

import DashboardCamera from "src/components/dashboard/DashboardCamera";

import DashboardLayout from "src/components/dashboard/DashboardLayout";
import { getUserCamerasRequest, updateDashboardCamera } from "src/store/users/users.actions";
import { signOut } from "src/store/auth/auth.actions";
import DashboardCamerasDropdown from "src/components/dashboard/DashboardCamerasDropdown";
import { RouteComponentProps } from "react-router-dom";
import { styles } from "src/lib/styles";
import { Map } from "../../components/dashboard/MapContainer";
import { RouterProps } from "src/components/menu/menu";

const DashboardContainer: React.FC<RouteComponentProps<RouterProps> & IStore & IDispatchProps> = props => {
  const {
    refetchUserCameras, users, hideDashboardCamera 
  } = props;

  const { currentUser } = users;
  const [isOpen, setIsOpen] = useState(false);
  const route = props.location.pathname.includes("map") ? "map" : "dashboard";
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dashboardCameras = props.users.dashboardCameras || [];
  const cameraLocations = props.cameraLocations.cameraLocations.filter(camera => dashboardCameras.find(dashboardCamera => dashboardCamera.camera_location_id === camera.id && dashboardCamera.show));
  const MAX_PER_ROW = DashboardLayout.utils.getColumnCount(cameraLocations.length);
  const cameraRows = DashboardLayout.utils.chunkArray(cameraLocations, MAX_PER_ROW);
  const totalRows = cameraRows.length;

  // Every 30 seconds, refetch the user cameras
  useEffect(() => {
    if (currentUser?.id) {
      // We clear the last viewed camera from local storage when the user visits the dashboard
      window.localStorage.removeItem("@interval-films-last-camera");
      refetchUserCameras(currentUser.id);
    }

    const interval = setInterval(() => {
      if (currentUser?.id) {
        refetchUserCameras(currentUser.id);
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [refetchUserCameras, currentUser?.id]);

  const numberOfSites = cameraLocations.reduce((acc: { [key: string]: number }, cameraLocation) => {
    if (cameraLocation.site?.name) {
      acc[cameraLocation.site.name] = (acc[cameraLocation.site.name] || 0) + 1;
    }

    return acc;
  }, {});

  return (
    <DashboardLayout.PageContainer>
      <Header>
        <Row style={{
          width: "100vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between" 
        }}>

          <Logo hideOnMobile={false} />

          <Menu 
            route={route}
            navigate={props.history.push}
            match={props.match}
            location={props.location}
            history={props.history}
          />

        </Row>
      </Header>

      <DashboardLayout.ContentContainer isMap={route === "map"}>
        <DashboardLayout.Sidebar>
          <DashboardLayout.SidebarItem>
            <div 
              onClick={() => {
                props.history.push("/customer/dashboard");
                setIsOpen(!isOpen);
              }}
              onMouseOver={() => {
                if (route === "dashboard") {  
                  // only on desktop:
                  if (window.innerWidth >= 768) {
                    setIsOpen(true);
                  }
                }
              }}
              onMouseLeave={event => {
              // only on desktop:
                if (window.innerWidth >= 768) {
                  event.stopPropagation();

                  if (dropdownRef.current) {
                  // If the mouse leaves the dropdown, close it unless we're hovering over the dropdown
                    if (!dropdownRef.current?.contains(event.relatedTarget as Node)) {
                      setIsOpen(false);
                    }
                  }
                }
              }}
            >
              <div style={{ position: "relative" }}
              >
                <FaBorderAll style={{
                  color: route === "dashboard" ? styles.primaryAccentColor : "white",
                  fontSize: "1.5rem" 
                }} />
                <div style={{
                  position: "absolute",
                  bottom: "-0.65rem",
                  right: "-0.65rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: styles.secondaryDarkColor,
                  borderRadius: "6rem",
                  width: "1.25rem",
                  height: "1.25rem"
                }}>
                  <span style={{
                    userSelect: "none",
                    color: "white",
                    fontSize: "0.75rem"
                  }}>{cameraLocations.length}</span>
                </div>
              </div>
              <p style={{ userSelect: "none" }}>Dashboard</p>
            </div>
            <div onClick={() => {
              props.history.push("/customer/map");
              setIsOpen(false);
            }}>
              <FaEarthEurope style={{
                color: route === "map" ? styles.primaryAccentColor : "white",
                fontSize: "1.5rem" 
              }} />
              <p style={{ userSelect: "none" }}>Map</p>
            </div>
          </DashboardLayout.SidebarItem>

          <DashboardCamerasDropdown ref={dropdownRef} isOpen={isOpen} onClose={() => {
            setIsOpen(false);
          }} />
        </DashboardLayout.Sidebar>
        
        {route === "dashboard" && (
          <DashboardLayout.GridContainer totalRows={totalRows}>
            {cameraRows.map((rowCameraLocations, rowIndex) => (
              <DashboardLayout.GridRow 
                key={rowIndex} 
                totalRows={totalRows}
                itemsInRow={rowCameraLocations.length}
              >
                {rowCameraLocations.map(cameraLocation => {
                  const isOnlyCameraOfSite = numberOfSites[cameraLocation.site?.name || ""] === 1;
                  
                  return (
                    <DashboardCamera
                      showCameraLocationName={!isOnlyCameraOfSite}
                      hideCamera={
                        () => hideDashboardCamera(cameraLocation.id)
                      }
                      navigate={props.history.push} 
                      key={cameraLocation.id} 
                      cameraLocation={cameraLocation} />
                  );
                })}
              </DashboardLayout.GridRow>
            ))}
          </DashboardLayout.GridContainer>
        )}

        {route === "map" && (
          <Map cameraLocations={cameraLocations} navigate={props.history.push} />
        )}
      </DashboardLayout.ContentContainer>
      
    </DashboardLayout.PageContainer>
  );
};

interface IDispatchProps {
  refetchUserCameras: (userId: number) => void
  signOut: () => void
  hideDashboardCamera: (camera_location_id: number) => void
}

const mapStateToProps = (state: IStore) => state;

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IDispatchProps => {
  return { 
    signOut: () => dispatch(signOut()),
    refetchUserCameras: (userId: number) => dispatch(getUserCamerasRequest(userId)),
    hideDashboardCamera: (camera_location_id: number) => dispatch(updateDashboardCamera({
      camera_location_id,
      show: false 
    }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
