import * as React from "react";
import styled, { css, keyframes } from "styled-components";
import { styles } from "../../lib/styles";
import { Text, Icon } from "../";
import moment from "moment";

interface IProps {
  onActivateLatestMode: () => void;
  onChangeDate: (startDate: moment.Moment, endDate: moment.Moment) => void;
  isLatestOn: boolean;
  dates: string[];
  isDelayed: boolean;
}

interface ILatestButtonProps {
  isLatestOn: boolean;
}

interface IState {
  mode: string;
  isOpen: boolean;
}

const initialState: IState = {
  isOpen: false,
  mode: "Latest"
};

const ToolTip = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  background: ${styles.textColor};
  color: ${styles.primaryDarkColor};
  font-weight: 500;
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: 100%;
  right: 0px;
  width: 190px;
  padding: 8px 12px;
  margin-bottom: 15px;
  z-index: 99;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
    border-color: ${styles.textColor} transparent transparent transparent;
    position: absolute;
    bottom: -7px;
    right: 10px;
  }
`;

const Wrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  position: relative;

  &:hover {
    ${ToolTip} {
      opacity: 1;
    }
  }
`;

const LatestButtonWrapper = styled.div<ILatestButtonProps>`
  background-color: ${styles.primaryDarkColor};
  padding: 8px 14px;
  border-radius: 30px 0 0 30px;
  padding-right: 8px;
  border: 1px solid;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    & span {
      color: ${styles.primaryTextColor};
    }
  }

  & span {
    transition: all 0.2s ease-in-out;
    color: ${props =>
    props.isLatestOn ? styles.primaryTextColor : styles.secondaryTextColor};
  }
`;

const latestPulse = keyframes`
  from {
    transform: translate(-50%, -50%) scale(0.4);
  }

  to {
    transform: translate(-50%, -50%) scale(1);
  }
`;

const LatestIcon = styled.div<ILatestButtonProps>`
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border-radius: 100%;
  position: relative;
  box-shadow: 0 0 1px 0px
      ${props =>
    props.isLatestOn
      ? styles.primaryAccentColor
      : styles.secondaryTextColor}
      inset,
    0 0 1px 0px
      ${props =>
    props.isLatestOn
      ? styles.primaryAccentColor
      : styles.secondaryTextColor};

  border: 1px solid
    ${props =>
    props.isLatestOn ? styles.primaryAccentColor : styles.secondaryTextColor};

  &:after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 100%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.4);
    animation: ${props =>
    props.isLatestOn
      ? css`${latestPulse} 1s ease-in-out alternate infinite`
      : "none"};
    background: ${props =>
    props.isLatestOn ? styles.primaryAccentColor : styles.fadedTextColor};
  }
`;

const DropdownWrapper = styled.div`
  border: 1px solid ${styles.secondaryTextColor};
  border-left: 0px;
  border-radius: 0 30px 30px 0;
  cursor: pointer;
  position: relative;
  width: 30px;

  svg {
    position: absolute;
    left: 50%;
    top: 54%;
    transform: translate(-50%, -50%);
  }
`;

const Dropdown = styled.div`
  position: absolute;
  width: 150px;
  z-index: 999;
  right: 0;
  bottom: 30px;
  background: black;
  padding: 10px;
  background: ${styles.secondaryDarkColor};
  border-right: 3px solid ${styles.primaryDarkColor};
  border-bottom: 3px solid ${styles.primaryDarkColor};
`;

const DropdownItem = styled.div`
  padding: 10px;

  &.active:hover {
    color: ${styles.primaryAccentColor};
  }

  &.disabled {
    opacity: 0.5;
  }
`;

const DayDelayIcon = styled.div<{ isLatestOn: boolean }>`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-top: -1px;
    fill: ${props =>
    props.isLatestOn ? styles.primaryAccentColor : styles.fadedTextColor};
  }
`;

class LatestButton extends React.Component<IProps, IState> {
  public readonly state: IState = initialState;
  protected wrapper: HTMLDivElement;
  protected boundOnMousedown: () => void;

  constructor(props: IProps) {
    super(props);
    this.boundOnMousedown = this.handleClickOutside.bind(this);
  }

  public UNSAFE_componentWillMount() {
    document.addEventListener(
      "mousedown", this.boundOnMousedown, false
    );
  }

  public componentWillUnmount() {
    document.removeEventListener(
      "mousedown", this.boundOnMousedown, false
    );
  }

  public render() {
    const {
      onActivateLatestMode, isLatestOn, dates
    } = this.props;

    const { isOpen, mode } = this.state;
    const lastDate = moment.utc(dates[0]).endOf("day");
    const firstDate = moment.utc(dates[dates.length - 1]).startOf("day");
    const totalDays = lastDate.diff(firstDate, "days");

    const latestOptions = [
      this.setDateOption(0, "Latest"),
      this.setDateOption(2, "3 days"),
      this.setDateOption(6, "7 days"),
      this.setDateOption(30, "31 days"),
      this.setDateOption(364, "Year"),
      this.setDateOption(totalDays, "All")
    ];

    const ref = (element: HTMLDivElement) => {
      this.wrapper = element;
    };

    return (
      <div ref={ref}>
        <Wrapper>
          <LatestButtonWrapper
            onClick={
              mode === "Latest"
                ? onActivateLatestMode
                : () => this.handleDropdownToggle()
            }
            isLatestOn={isLatestOn}
          >
            {mode === "Latest" && this.props.isDelayed && (
              <ToolTip>Images on this camera are delayed by 24 hours.</ToolTip>
            )}
            {mode === "Latest" && (
              <>
                {this.props.isDelayed ? (
                  <DayDelayIcon isLatestOn={isLatestOn}>
                    <Icon icon="dayDelay" size={15} />
                  </DayDelayIcon>
                ) : (
                  <LatestIcon isLatestOn={isLatestOn} />
                )}
              </>
            )}
            <Text
              style={{
                fontSize: "12px",
                fontWeight: 400,
                letterSpacing: "1px",
                textTransform: "uppercase"
              }}
            >
              {mode}
            </Text>
          </LatestButtonWrapper>
          <DropdownWrapper onClick={() => this.handleDropdownToggle()}>
            <Icon
              style={{
                left: "50%",
                position: "absolute",
                right: "7px",
                top: "50%",
                transform: "translate(-50%, -40%)"
              }}
              icon="chevron-down"
              size={15}
            />
            {isOpen && (
              <Dropdown>
                {latestOptions.map((item, index) => (
                  <DropdownItem
                    className={item.active ? "active" : "disabled"}
                    key={index}
                    onClick={() => {
                      if (item.active) {
                        this.handleDateChange(
                          item.startDate,
                          item.endDate,
                          item.text
                        );
                      }
                    }}
                  >
                    {item.text}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          </DropdownWrapper>
        </Wrapper>
      </div>
    );
  }

  private handleDateChange(
    startDate: moment.Moment,
    endDate: moment.Moment,
    mode: string
  ) {
    const { onChangeDate, onActivateLatestMode } = this.props;

    this.setState({
      isOpen: false,
      mode
    });

    if (mode === "Latest") {
      onActivateLatestMode();
    }

    onChangeDate(startDate, endDate);
  }
  private handleDropdownToggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  private setDateOption(fromNow: number, text: string) {
    const { dates } = this.props;
    const latest = moment.utc(dates[0]).endOf("day");
    const earliest = moment.utc(dates[dates.length - 1]).startOf("day");

    const target = moment
      .utc(dates[0])
      .subtract(fromNow, "days")
      .startOf("day");

    return {
      active: !target.isBefore(earliest),
      endDate: latest,
      startDate: target,
      text
    };
  }

  private handleClickOutside(e: Event) {
    if (this.wrapper && this.wrapper.contains(e.target as Node | null)) {
      return;
    } else {
      if (this.wrapper) {
        this.setState({ isOpen: false });
      }
    }
  }
}

export default LatestButton;
