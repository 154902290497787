import React, {
  useEffect,
  useReducer,
  useRef,
  useState
} from "react";
import { connect } from "react-redux";
import Redux from "redux";
import { ThunkDispatch } from "redux-thunk";
import Button from "src/components/button";
import { signOut } from "src/store/auth/auth.actions";
import { updateCameraScheduleRequest } from "src/store/camera-locations/camera-locations.actions";
import {
  AdjustableSchedule,
  Day, 
  ICameraLocation,
  ScheduleForDay
} from "src/store/camera-locations/camera-locations.api";
import { IStore } from "src/types/store/store";

import Panel from "./SettingsPanelComponents";
import { scheduleReducer, initalState } from "./ScheduleReducer";
import { IntervalDropdown } from "./IntervalDropdown";
import TextInput from "src/components/text-input";
import TimeRangeSlider from "./TimeRangeSlider";
import {
  Clipboard, ClipboardPlus, Copy 
} from "lucide-react";

interface IProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  cameraLocation?: ICameraLocation | null;
}

const calculateImagesForDay = (schedule: ScheduleForDay): number => {
  if (!schedule.enabled) return 0;

  const startMinutes = schedule.start_time.split(":").map(Number);
  const endMinutes = schedule.end_time.split(":").map(Number);
  const startTotalMinutes = startMinutes[0] * 60 + startMinutes[1];
  const endTotalMinutes = endMinutes[0] * 60 + endMinutes[1];
  const totalMinutes = endTotalMinutes - startTotalMinutes;
  // Convert interval to minutes
  let intervalInMinutes = schedule.interval_value;

  switch (schedule.interval_unit) {
    case "hours":
      intervalInMinutes *= 60;
      break;
    case "seconds":
      intervalInMinutes /= 60;
      break;
    default: // minutes
      break;
  }
  
  return Math.floor(totalMinutes / intervalInMinutes) + 1; // +1 for inclusive end
};

const calculateStoragePerImage = () => {
  // Assuming average image has 14MB of thumbnails to store
  return 14;
};

const SchedulePanel: React.FC<IProps & IDispatchProps & IStore> = ({
  isOpen, setOpen, cameraLocation, updateCameraSchedule
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [schedule, dispatch] = useReducer(scheduleReducer, initalState);
  const [dayToCopy, setDayToCopy] = useState<Day | null>(null);

  useEffect(() => {
    dispatch({
      type: "RESET_TO_CAMERA_LOCATION",
      cameraLocation: cameraLocation || null
    });
  }, [cameraLocation]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpen]);

  const [selectedDay, setSelectedDay] = useState<Day>(Day.Monday);
  
  const daysOfWeek = [
    Day.Monday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
    Day.Saturday,
    Day.Sunday
  ];

  const imagesPerDay = calculateImagesForDay(schedule[selectedDay]);

  const imagesPerWeek = Object.values(schedule).reduce((total, daySchedule) => 
    total + calculateImagesForDay(daySchedule), 0);

  const imagesPerMonth = imagesPerWeek * 4.345; // Average weeks in a month
  const imagesPerYear = imagesPerWeek * 52.143; // Weeks in a year
  const storagePerImage = calculateStoragePerImage();

  const roundStorageToUsefulUnits = (storage: number) => {
    if (storage > 1000) {
      return `${(storage / 1000).toFixed(1)} GB`;
    }

    return `${storage.toFixed(1)} MB`;
  };

  return (
    <div ref={wrapperRef}>
      <Panel.BackgroundOverlay onClick={() => setOpen(false)} isOpen={isOpen} />
      <Panel.CameraSettingsPanelWrapper>
        <Panel.DaysOfWeek>
          {daysOfWeek.map((day, index) => (
            <Panel.DayOfWeek 
              key={index} 
              isActive={selectedDay === day} 
              isDisabled={selectedDay !== day && !schedule[day].enabled}
              onClick={() => setSelectedDay(day)}>
              {day[0]}
            </Panel.DayOfWeek>
          ))}
        </Panel.DaysOfWeek>
        <Panel.CameraSettingsPanelHeader>
          {`${selectedDay}'s Schedule`}
        </Panel.CameraSettingsPanelHeader>
        <Panel.SettingsWrapper>
          <Panel.Row style={{
            alignItems: "center",
            justifyContent: "center",
            gap: "1.5rem",
            padding: "0.5rem"
          }}>
            <Panel.SettingLabel>Enabled</Panel.SettingLabel>
            <Panel.ToggleTrigger 
              isOn={schedule[selectedDay].enabled}
              onClick={() => {
                dispatch({
                  type: "UPDATE_SETTING",
                  key: selectedDay,
                  value: {
                    ...schedule[selectedDay],
                    enabled: !schedule[selectedDay].enabled
                  }
                });
              }} 
            />
          </Panel.Row>
          
          <Panel.Row style={{
            alignItems: "center",
            justifyContent: "center",
            gap: "1.5rem",
            padding: "0.5rem"
          }}> 
            <Panel.SettingLabel>Every</Panel.SettingLabel>
            <TextInput
              value={schedule[selectedDay].interval_value.toString()}
              type="number"
              style={{ width: "4rem" }}
              min={1}
              onChange={e => {
                dispatch({
                  type: "UPDATE_SETTING",
                  key: selectedDay,
                  value: {
                    ...schedule[selectedDay],
                    interval_value: +e.target.value!
                  }
                });
              }}
            />
            
            <IntervalDropdown
              setting={{
                value: schedule[selectedDay].interval_unit,
                options: [
                  "seconds",
                  "minutes",
                  "hours"
                ]
              }}
              onChange={value => {
                dispatch({
                  type: "UPDATE_SETTING",
                  key: selectedDay,
                  value: {
                    ...schedule[selectedDay],
                    interval_unit: value as "seconds" | "minutes" | "hours"
                  }
                });
              }}
            />
          </Panel.Row>
          <Panel.Row style={{
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "1.5rem",
            paddingBottom: "0.5rem"
          }}>
            <TimeRangeSlider 
              startTime={schedule[selectedDay].start_time || "09:00"}
              endTime={schedule[selectedDay].end_time || "17:00"}
              onChange={(startTime, endTime) => {
                dispatch({
                  type: "UPDATE_SETTING",
                  key: selectedDay,
                  value: {
                    ...schedule[selectedDay],
                    start_time: startTime,
                    end_time: endTime
                  }
                });
              }}
            />
          </Panel.Row>
          <Panel.Row style={{
            alignItems: "center",
            justifyContent: "center",
            gap: "1.5rem",
            paddingBottom: "0.5rem"
          }}>
            <Panel.CopyPasteButton
              onClick={() => {
                setDayToCopy(selectedDay);
              }}>

              <Copy size={16}/>
              <Panel.SettingLabel style={{ fontSize: "0.8rem" }}>COPY</Panel.SettingLabel>
            </Panel.CopyPasteButton>
            <Panel.CopyPasteButton 
              style={{ opacity: dayToCopy ? 1 : 0.5 }}
              onClick={() => {
                if (dayToCopy) {
                  dispatch({
                    type: "UPDATE_SETTING",
                    key: selectedDay,
                    value: schedule[dayToCopy]
                  });
                }
              }}>

              <Clipboard size={16}/>
              <Panel.SettingLabel style={{ fontSize: "0.8rem" }}>PASTE</Panel.SettingLabel>
            </Panel.CopyPasteButton>
            <Panel.CopyPasteButton 
              style={{ opacity: dayToCopy ? 1 : 0.5 }}
              onClick={() => {
                if (dayToCopy) {
                  dispatch({
                    type: "UPDATE_ALL_SETTINGS",
                    value: schedule[dayToCopy]
                  });
                }
              }}>

              <ClipboardPlus size={16}/>
              <Panel.SettingLabel style={{ fontSize: "0.8rem" }}>PASTE ALL</Panel.SettingLabel>
            </Panel.CopyPasteButton>
          </Panel.Row>
        </Panel.SettingsWrapper>
        <Panel.CameraSettingsPanelHeader>
          Summary
        </Panel.CameraSettingsPanelHeader>
        <Panel.SettingsWrapper>
          <Panel.Row>
            <Panel.SettingLabel>Day</Panel.SettingLabel>
            <Panel.SummaryValueBox>
              <Panel.SettingValue>
                {imagesPerDay.toLocaleString(undefined, { maximumFractionDigits: 0 })} images,
              </Panel.SettingValue>
              <Panel.SettingValue>
                {roundStorageToUsefulUnits(imagesPerDay * storagePerImage)}
              </Panel.SettingValue>
            </Panel.SummaryValueBox>
          </Panel.Row>
          <Panel.Row>
            <Panel.SettingLabel>Week</Panel.SettingLabel>
            <Panel.SummaryValueBox>
              <Panel.SettingValue>
                {imagesPerWeek.toLocaleString(undefined, { maximumFractionDigits: 0 })} images,
              </Panel.SettingValue>
              <Panel.SettingValue>
                {roundStorageToUsefulUnits(imagesPerWeek * storagePerImage)}
              </Panel.SettingValue>
            </Panel.SummaryValueBox>
          </Panel.Row>
          <Panel.Row>
            <Panel.SettingLabel>Month</Panel.SettingLabel>
            <Panel.SummaryValueBox>
              <Panel.SettingValue>
                {imagesPerMonth.toLocaleString(undefined, { maximumFractionDigits: 0 })} images,
              </Panel.SettingValue>
              <Panel.SettingValue>
                {roundStorageToUsefulUnits(imagesPerMonth * storagePerImage)}
              </Panel.SettingValue>
            </Panel.SummaryValueBox>
          </Panel.Row>
          <Panel.Row>
            <Panel.SettingLabel>Year</Panel.SettingLabel>
            <Panel.SummaryValueBox>
              <Panel.SettingValue>
                {imagesPerYear.toLocaleString(undefined, { maximumFractionDigits: 0 })} images,
              </Panel.SettingValue>
              <Panel.SettingValue>
                {roundStorageToUsefulUnits(imagesPerYear * storagePerImage)}
              </Panel.SettingValue>
            </Panel.SummaryValueBox>
          </Panel.Row>
        </Panel.SettingsWrapper>
        <Panel.SettingsWrapper>
          <Panel.Row>
            <Button
              text="Cancel"
              clickHandler={() => {
                setOpen(false);
              }}
            />
            <Button
              text="Save"
              clickHandler={() => {
                updateCameraSchedule(cameraLocation?.id.toString() || "", schedule);
              }}
            />
          </Panel.Row>
        </Panel.SettingsWrapper>
      </Panel.CameraSettingsPanelWrapper>
    </div>
  );
};

interface IDispatchProps {
  signOut: () => void;
  updateCameraSchedule: (cameraLocationId: string, schedule: AdjustableSchedule) => void;
}

const mapStateToProps = (state: IStore) => state;

const mapDispatchToProps = (dispatch: ThunkDispatch<IStore, void, Redux.Action>): IDispatchProps => {
  return { 
    signOut: () => dispatch(signOut()),
    updateCameraSchedule: (cameraLocationId: string, schedule: AdjustableSchedule) => dispatch(updateCameraScheduleRequest(cameraLocationId, schedule))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePanel);
