import moment from "moment";
import React, {
  useEffect, useState, memo 
} from "react";
import getImageByType from "src/lib/getImageByType";
import { styles } from "src/lib/styles";
import { ICameraLocation } from "src/store/camera-locations/camera-locations.api";
import { IThumbnail } from "src/types/store/images";
import styled from "styled-components";

interface IGridItemProps {
  cameraLocation: ICameraLocation;
  navigate: (path: string) => void;
}

const MapCameraContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 120px;
  aspect-ratio: 16/9;
  cursor: pointer;
  border-radius: 0.5rem;
  border: 2px solid ${styles.primaryDarkColor};

  transition: border-color 0.2s ease-in-out;
  &:hover {
    border-color: ${styles.primaryAccentColor};
  }
`;

const StyledImage = styled.div<{topOffset: number, src?: string}>`
  width: 100%;
  object-fit: cover;
  background-image: url(${props => props.src});
  background-position: 50% ${props => props.topOffset}%;
  background-size: cover;
  background-repeat: no-repeat;


  height: 100%;
  aspect-ratio: auto;

`;

interface ICameraImageProps {
  imageUrl?: string;
  topOffset: number;
}

// Helper to extract base URL and expiry from signed URL
const parseSignedUrl = (url?: string) => {
  if (!url) return {
    baseUrl: "",
    expiresAt: 0 
  };

  try {
    const urlObj = new URL(url);
    const expiresParam = urlObj.searchParams.get("X-Amz-Expires");
    const dateParam = urlObj.searchParams.get("X-Amz-Date");
    // Get expiry time by parsing X-Amz-Date and adding X-Amz-Expires seconds
    let expiresAt = 0;

    if (dateParam && expiresParam) {
      const dateStr = dateParam.slice(0, 8); // YYYYMMDD format
      const timeStr = dateParam.slice(9, 15); // HHMMSS format
      const date = moment(`${dateStr} ${timeStr}`, "YYYYMMDD HHmmss").valueOf();

      expiresAt = date + (parseInt(expiresParam, 10) * 1000);
    }

    // Get just the path portion of the URL (everything after the hostname and before the query string)
    const pathOnly = urlObj.pathname;

    return {
      baseUrl: pathOnly,
      expiresAt 
    };
  } catch {
    return {
      baseUrl: "",
      expiresAt: 0 
    };
  }
};

const CameraImage = memo(({ imageUrl, topOffset }: ICameraImageProps) => {
  if (!imageUrl) return <div></div>;

  return <StyledImage src={imageUrl} topOffset={topOffset} />;
}, (prevProps, nextProps) => {
  if (prevProps.topOffset !== nextProps.topOffset) return false;

  if (!prevProps.imageUrl && !nextProps.imageUrl) return true;

  if (!prevProps.imageUrl || !nextProps.imageUrl) return false;

  const prev = parseSignedUrl(prevProps.imageUrl);
  const next = parseSignedUrl(nextProps.imageUrl);

  // If base URLs are different, we should re-render
  if (prev.baseUrl !== next.baseUrl) return false;

  // If the URL is about to expire (within 5 minutes), allow re-render
  const fiveMinutesFromNow = Date.now() + 5 * 60 * 1000;

  if (prev.expiresAt < fiveMinutesFromNow) return false;

  // Otherwise, prevent re-render
  return true;
});

CameraImage.displayName = "CameraImage";

const MapCamera = memo(({ cameraLocation, navigate }: IGridItemProps) => {
  const [thumbnail, setThumbnail] = useState<IThumbnail | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      const thumbnail = await getImageByType("tablet", cameraLocation.latest_image?.thumbnails || []);

      setThumbnail(thumbnail);
    };

    fetchImage();
  }, [cameraLocation.latest_image?.thumbnails]);

  return (
    <MapCameraContainer onClick={() => navigate(`/customer/${cameraLocation.site?.customer?.id}/site/${cameraLocation.site_id}/camera/${cameraLocation.uuid}/images`)}>
      <CameraImage imageUrl={thumbnail?.s3_url} topOffset={cameraLocation.offset_top} />
    </MapCameraContainer>
  );
});

MapCamera.displayName = "MapCamera";

export default MapCamera;
