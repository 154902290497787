import { ICameraLocation, AdjustableSetting } from "src/store/camera-locations/camera-locations.api";

export interface IAdjustableSetting {
  label: string;
  icon: React.ReactNode;
  options: string[] | number[];
  value?: string | number;
  key: keyof Pick<ICameraLocation, 
    "desired_image_format" | 
    "desired_iso" | 
    "desired_aperture" | 
    "desired_shutter" | 
    "desired_exposure_comp" | 
    "desired_white_balance" |
    "desired_camera_lid_open" |
    "desired_heater_on" |
    "desired_camera_on"
  >;
}

export type SettingsAction<T extends keyof AdjustableSetting> = {
  type: "UPDATE_SETTING";
  key: T;
  value: AdjustableSetting[T];
} | {
  type: "RESET_TO_CAMERA_LOCATION";
  cameraLocation: ICameraLocation | null;
};

export const settingsReducer = <T extends keyof AdjustableSetting>(state: AdjustableSetting, action: SettingsAction<T>): AdjustableSetting => {
  switch (action.type) {
    case "UPDATE_SETTING":
      return {
        ...state,
        [action.key]: action.value
      };
    case "RESET_TO_CAMERA_LOCATION":
      if (!action.cameraLocation) return state;

      return {
        desired_camera_lid_open: action.cameraLocation.desired_camera_lid_open || 0,
        desired_camera_on: action.cameraLocation.desired_camera_on || 0,
        desired_heater_on: action.cameraLocation.desired_heater_on || 0,
        desired_image_format: action.cameraLocation.desired_image_format || "JPEG",
        desired_iso: action.cameraLocation.desired_iso || 100,
        desired_aperture: action.cameraLocation.desired_aperture || 2.8,
        desired_shutter: action.cameraLocation.desired_shutter || "1/125",
        desired_exposure_comp: action.cameraLocation.desired_exposure_comp || 0,
        desired_white_balance: action.cameraLocation.desired_white_balance || "Auto"
      };
    default:
      return state;
  }
};